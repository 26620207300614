import { useContext, useMemo, useState } from 'react';
import MantineTable from '../../../../common/table/mantine';
import { CostingRequestsContext } from '../../../../contexts/CostingRequest';
import { Alert, Button, Grid } from '@mantine/core';
import { CountrySelect, TableHeaderContent } from '../../styles';
import withConfirm from '../../../../components/common/confirm-dialog';
import { AppLoader } from '../../../../components/common/loader';

export const plantColumns = (type) => [
  {
    id: 'Plant',
    header: `${type} Plants`,
    accessorFn: (row) =>
      row.Plant || row.producing_plant || row.selling_plant || row.buying_plant || '',
  },
  {
    id: 'Country',
    header: `${type} Country`,
    accessorFn: (row) =>
      row.Country || row.producing_country || row.selling_country || row.buying_country || '',
  },
  {
    id: 'Zone',
    header: `${type} Zone`,
    accessorFn: (row) =>
      row.Zone || row.producing_zone || row.selling_zone || row.buying_zone || '',
  },
];
const PlantsTable = ({ title, onSubmit, info, confirm, loading = false, limit }) => {
  const { plants, countries } = useContext(CostingRequestsContext);
  const [activeMaterials, setActiveMaterials] = useState(info?.active || {});
  const [selectedCountry, setSelectedCountry] = useState(info.country || null);

  const isPlantSelection = ['Select Production Plant', 'Select Selling Plant'].includes(title);
  const gridSpan = isPlantSelection ? 10 : 12;

  let columns = useMemo(() => plantColumns(title.split(' ')[1]), [plants]);

  const handleRowSelection = (data) => {
    let currentValues = { ...activeMaterials };
    currentValues = { ...data };

    setActiveMaterials(currentValues);
  };

  const limitExceeded = () => {
    return Object.keys(activeMaterials).length > limit;
  };

  return (
    <>
      <TableHeaderContent>
        <Grid>
          {isPlantSelection && (
            <Grid.Col span={3}>
              <CountrySelect
                searchable
                value={selectedCountry}
                placeholder='Select a country'
                data={countries}
                onChange={(value) => {
                  if (value !== selectedCountry && selectedCountry) {
                    return confirm(
                      () => {
                        setActiveMaterials({});
                        setSelectedCountry(value);
                      },
                      {
                        title: 'Confirm!',
                        description:
                          'Are you sure you want to change the country? This action will remove all selected plants.',

                        confirmationText: 'Confirm',
                      },
                    );
                  } else {
                    setSelectedCountry(value);
                  }
                }}
                clearable
              />
            </Grid.Col>
          )}
          <Grid.Col span={gridSpan}>
            {limitExceeded() && (
              <Alert title='' color='red' className='mb-2'>
                {`Only ${limit} selections are allowed`}
              </Alert>
            )}
          </Grid.Col>
        </Grid>
      </TableHeaderContent>

      <MantineTable
        columns={columns}
        initialData={
          ['Select Production Plant', 'Select Selling Plant'].includes(title)
            ? selectedCountry
              ? plants.filter((plant) => plant.Country === selectedCountry)
              : []
            : plants
        }
        unique={'Plant'}
        handleRowSelection={handleRowSelection}
        activeRows={activeMaterials}
        enableRowSelection={true}
        loading={false}
        showSelectedToggle={true}
        hideSelectColumn={false}
        showResetAll={false}
        fullScreen={false}
        multiRowSelection={!['Select SKU'].includes(title)}
      />

      <Grid justify='flex-end' style={{ marginTop: '16px' }}>
        <Grid.Col span={2}>
          <Button
            color='dark'
            disabled={limitExceeded()}
            fullWidth
            onClick={() => onSubmit(activeMaterials, title)}
          >
            {loading ? <AppLoader size='sm' variant='dark' /> : 'Confirm'}
          </Button>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default withConfirm(PlantsTable);
