import { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import MantineTable from '../../../../../common/table/mantine';
import { CustomTextArea } from '../../../../../common/inputs/CustomTextArea';
import { Context } from '../context';
import { Button } from '@mantine/core';
import { AppLoader } from '../../../../../components/common/loader';
import { CostingRequestsContext } from '../../../../../contexts/CostingRequest';
import { CostValueTemp } from '../helper';

export const Table = ({ data = [], loading, rightButtons, activePlant }) => {
  const [initialState, setInitialState] = useState([]);
  const { crStepStatus } = useContext(CostingRequestsContext);
  const { form, onFormChange, onFormSubmit, readOnly, activePage } = useContext(Context);
  const [saveLoader, setSaveLoader] = useState({});
  const commentKey = activePage === 'vicApproval' ? 'vic_comment' : 'comment';

  useEffect(() => {
    setInitialState(data);
  }, [data]);

  const isInProgress = (cost) => {
    const pageKey = activePage === 'vicSubmission' ? 'VIC Submission' : 'VIC Approval';
    const isVicClosed =
      (crStepStatus?.[activePlant.producing_plant]?.[pageKey] || '').toLowerCase() === 'closed';
    return (cost.status || '').toLowerCase() === 'in progress' && !isVicClosed;
  };

  const handleSubmit = async (id) => {
    try {
      setSaveLoader({ ...saveLoader, [id]: true });
      await onFormSubmit(form[id], id, 'bom');
      updateInitialData(form[id], id);
    } catch (e) {
      console.log(e);
    } finally {
      setSaveLoader({ ...saveLoader, [id]: false });
    }
  };

  const updateInitialData = (info, id) => {
    const index = initialState.findIndex((item) => item.component_id === id);
    if (index > -1) {
      const updatedValues = [...initialState];
      updatedValues[index] = {
        ...updatedValues[index],
        [commentKey]: info?.[commentKey] || '',
      };
      setInitialState(updatedValues);
    }
  };

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'component_description',
          header: 'BOM/Norm Component',
        },
        {
          accessorKey: 'component',
          header: 'Resource',
        },

        {
          header: 'Total Value (per HL)',
          accessorKey: `total_value_excel`,
          Cell: ({ row }) => {
            return row.original.total_value_final.map((value, index) => (
              <CostValueTemp key={index} cost={value} />
            ));
          },
        },

        {
          accessorKey: 'currency',
          header: 'Curr',
        },
        {
          header: 'Qty',
          accessorKey: `quantity_excel`,
          Cell: ({ row }) => {
            return row.original.quantity_final.map((value, index) => (
              <CostValueTemp key={index} cost={value} />
            ));
          },
        },

        {
          accessorKey: 'component_unit',
          header: 'Qty Unit',
        },

        {
          header: 'Price',
          accessorKey: `price_value_excel`,
          Cell: ({ row }) => {
            return row.original.price_final.map((value, index) => (
              <CostValueTemp key={index} cost={value} />
            ));
          },
        },

        {
          accessorKey: 'un',
          header: 'Price Unit',
        },

        {
          header: 'Price Per Unit',
          accessorKey: `price_unit_excel`,
          Cell: ({ row }) => {
            return row.original.price_unit_final.map((value, index) => (
              <CostValueTemp key={index} cost={value} />
            ));
          },
        },

        {
          header: 'Scrap(%)',
          accessorKey: `scrap_excel`,
          Cell: ({ row }) => {
            return row.original.scrap_final.map((value, index) => (
              <CostValueTemp key={index} cost={value} />
            ));
          },
        },

        activePage === 'vicApproval' && {
          header: 'Comment (Submitted)',
          accessorKey: 'comment',
          Cell: ({ row }) => {
            if (row.original.isTotal) return '';
            return (
              <div>
                <div style={{ alignItems: 'center', marginBottom: '8px' }}>
                  <p key={row.original?.component}>{row?.original?.comment}</p>
                </div>
              </div>
            );
          },
        },

        {
          header: 'Comment',
          accessorKey: commentKey,
          Cell: ({ row }) => {
            const { component_id, read_only_bom } = row.original;

            return (
              <div>
                <div style={{ alignItems: 'center', marginBottom: '8px' }}>
                  <CustomTextArea
                    value={
                      form[component_id]
                        ? form[component_id]?.[commentKey]
                        : row.original?.[commentKey] || ''
                    }
                    placeholder='Comment here'
                    onChange={(value) => onFormChange(value, component_id, commentKey)}
                    disabled={!isInProgress(row.original) || readOnly || read_only_bom}
                  />
                </div>
              </div>
            );
          },
        },
        {
          id: 'action',
          header: 'Action',
          Cell: ({ row }) => {
            const { component_id, read_only_bom } = row.original;
            return (
              <Button
                disabled={
                  !form[component_id] ||
                  saveLoader[component_id] ||
                  !isInProgress(row.original) ||
                  readOnly ||
                  read_only_bom
                }
                color='dark'
                fullWidth
                size='sm'
                radius='sm'
                className='add-supplier-button'
                onClick={() => handleSubmit(component_id)}
              >
                {saveLoader[component_id] ? <AppLoader size='sm' /> : 'Submit'}
              </Button>
            );
          },
        },
      ].filter((column) => column),
    [form, saveLoader, readOnly, crStepStatus],
  );

  return (
    <>
      <MantineTable
        columns={columns}
        initialData={initialState}
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        pageSize={10}
        initialState={{
          sorting: [
            {
              id: 'component',
              desc: true,
            },
          ],
        }}
        enableColumnFilter={false}
        enableFilters={false}
        enableExport={true}
        columnFilterDisplayMode={false}
        rightButtons={rightButtons}
        csvFilename={`vic_${
          activePage === 'vicApproval' ? 'approval' : 'submission'
        }_bom_${moment().format('YYYY-MM-DD')}`}
      />
    </>
  );
};
