import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Select, Tooltip } from '@mantine/core';
import {
  Artwork,
  ArtworkAdaptation,
  ArtworkManagement,
  Changeover,
  ChangeoverDate,
  SupplierChange,
  Comment,
  SupplierList,
  PreviewColumnValue,
} from './helper';
import { Suppliers } from './modal';
import { SupplierContext } from '../../../contexts/SupplierContext';
import moment from 'moment';
import MantineTable from '../../../common/table/mantine';
import { materialchangeOverOptions } from '../../../constants';
import { RfsContext } from '../../../contexts/RfsContext';
import { MixoverChangeAlert } from './mixChangeOverAlert';

const changeoverOptions = [
  {
    value: materialchangeOverOptions.hardChange,
    label: materialchangeOverOptions.hardChange,
  },
  {
    value: materialchangeOverOptions.softChange,
    label: materialchangeOverOptions.softChange,
  },
  {
    value: materialchangeOverOptions.mixChange,
    label: materialchangeOverOptions.mixChange,
  },
  {
    value: materialchangeOverOptions.notSoonerThan,
    label: materialchangeOverOptions.notSoonerThan,
  },
  {
    value: materialchangeOverOptions.notLaterThan,
    label: materialchangeOverOptions.notLaterThan,
  },
];

export const SupplierTable = ({
  isSubmissionReview = false,
  confirm,
  errors = {},
  vendors = [],
  pmoApproval = false,
}) => {
  const { supplierForm, setSupplierForm, setActivePlants, activePlants, plantsLoading } =
    useContext(SupplierContext);
  const { form } = useContext(RfsContext);
  const [mixChangeOverAlert, setMixChangeOverAlert] = useState(false);
  const [status, setStatus] = useState({ open: false, id: null });
  const [suppliers, setSuppliers] = useState([]);
  const { d2pFeedback = [] } = form;
  const formRef = useRef(null);
  formRef.current = supplierForm;

  useEffect(() => {
    if (vendors.length > 0) {
      setSelectedSuppliers();
    }
  }, [vendors]);

  const setSelectedSuppliers = () => {
    const currentValues = [...suppliers];

    vendors.forEach((item) => {
      currentValues.push({
        ...item,
        selected: false,
      });
    });

    setSuppliers(currentValues);
  };

  const resetTableData = () => {
    const currentValues = [...formRef.current];
    currentValues.forEach((val, key) => {
      currentValues[key]['artwork_required'] = null;
      currentValues[key]['artwork_change'] = null;
      currentValues[key]['artwork_adaption'] = null;
      currentValues[key]['changeover_date'] = null;
      currentValues[key]['supplier_change_type'] = null;
      currentValues[key]['changeover_type'] = null;
      currentValues[key]['leading_material'] = null;
      currentValues[key]['to_be_suppliers'] = '';
      currentValues[key]['comment'] = '';
    });
    setSupplierForm(currentValues);
  };

  const handleDefaultSet = (event, type, selectedRows, allSelected) => {
    const filteredIds = selectedRows.rowsById;
    let value =
      type === 'active'
        ? event.target.checked
        : type === 'changeover_date'
        ? moment(event).isValid()
          ? moment(event).format('YYYY-MM-DD')
          : null
        : event;

    if (value === materialchangeOverOptions.mixChange && !mixChangeOverEligibility()) {
      setMixChangeOverAlert(true);
      return;
    }

    const currentValues = [...formRef.current];
    currentValues.forEach((val, key) => {
      if (!filteredIds[val.scope_id] && !allSelected) return currentValues[key];

      currentValues[key][type] = value;
      if (type === 'mix_changeover') {
        currentValues[key]['leading_material'] = event;
      }
      if (currentValues[key]['artwork_required'] === 'No') {
        currentValues[key]['artwork_change'] = null;
        currentValues[key]['artwork_adaption'] = null;
      }

      if ((type === 'artwork_adaption' && value == 'No') || !value)
        currentValues[key]['artwork_change'] = null;

      if (
        currentValues[key]['changeover_type'] === materialchangeOverOptions.softChange ||
        currentValues[key]['changeover_type'] === materialchangeOverOptions.mixChange
      ) {
        currentValues[key]['changeover_date'] = null;
      }
    });
    setSupplierForm(currentValues);
  };

  const handleChange = (event, type, row, selectedRows) => {
    let value =
      type === 'active'
        ? event.target.checked
        : event && event.target
        ? (event?.target?.value || '').trim().length === 0
          ? event.target.value.trim()
          : event.target.value
        : type === 'changeover_date'
        ? moment(event).isValid()
          ? moment(event).format('YYYY-MM-DD')
          : null
        : event;

    const currentValues = [...formRef.current];

    const index = currentValues.findIndex((item) => item.scope_id === row.original.scope_id);

    if (type === 'changeover_type' && value === materialchangeOverOptions.mixChange) {
      handleDefaultSet(event, type, selectedRows, true);
    } else if (
      type === 'changeover_type' &&
      value !== materialchangeOverOptions.mixChange &&
      currentValues[index]['changeover_type'] === materialchangeOverOptions.mixChange
    ) {
      handleDefaultSet(null, type, selectedRows, true);
      handleDefaultSet(null, 'mix_changeover', selectedRows, true);
      handleDefaultSet(null, 'mix_changeover', selectedRows, true);
      currentValues[index][type] = value;
    } else if (type === 'changeover_type' && value === materialchangeOverOptions.softChange) {
      currentValues[index]['changeover_date'] = null;
    }

    currentValues[index][type] = value;

    if ((type === 'artwork_adaption' && value === 'No') || (type === 'artwork_adaption' && !value))
      currentValues[index]['artwork_change'] = null;

    if (
      (type === 'artwork_required' && value === 'No') ||
      (type === 'artwork_required' && !value)
    ) {
      currentValues[index]['artwork_change'] = null;
      currentValues[index]['artwork_adaption'] = null;
    }

    setSupplierForm(currentValues);
  };

  const handleSupplierSelect = (data, scope_id) => {
    let currentValues = [...formRef.current];

    if (scope_id === 'all') {
      currentValues = currentValues.map((val) => {
        return {
          ...val,
          to_be_supplier: data,
        };
      });
    } else {
      const index = currentValues.findIndex((val) => val.scope_id === scope_id);
      currentValues[index]['to_be_supplier'] = data;
    }

    setStatus({ open: false, id: null });
    setSupplierForm(currentValues);
  };

  const mixChangeOverEligibility = () => {
    return (supplierForm || []).length > 1;
  };

  const getMixChangeOverOptions = () => {
    let materials = [];
    supplierForm.forEach((item) => {
      materials.push(item.material_code);
    });

    materials = [...new Set(materials)];

    return materials || [];
  };

  const handleMixOverChange = (event, row, type, selectedRows, allSelected) => {
    handleDefaultSet(event, type, selectedRows, true);
  };

  const handleRowSelection = (data) => {
    let currentValues = { ...activePlants };
    currentValues = { ...data };

    setActivePlants(currentValues);
  };

  const getMinChangeOverDate = () => {
    const { first_shipment } = { ...form.rfsInformation };

    return moment(first_shipment, 'YYYY-MM-DD').isValid()
      ? moment(first_shipment, 'YYYY-MM-DD').toDate()
      : moment().add('days', 1).toDate();
  };

  const getValuesFromD2p = (materialCode, type) => {
    const index = d2pFeedback.findIndex(({ material_code }) => material_code === materialCode);
    return index > -1 ? (d2pFeedback[index][type] === 1 ? 'Yes' : 'No') : '';
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'material_code',
        header: 'Material Code',
        Header: ({ table }) => (
          <Tooltip label='Material Code'>
            <div title=''>Material Code</div>
          </Tooltip>
        ),
        enableEditing: false,
      },
      {
        accessorKey: 'material_description',
        header: 'Material Description',
        Header: ({ table }) => (
          <Tooltip label='Material Description'>
            <div title=''>Material Description</div>
          </Tooltip>
        ),
        enableEditing: false,
      },
      {
        header: 'Plant',
        accessorKey: 'plant',
        Header: ({ table }) => (
          <Tooltip label='Plant'>
            <div title=''>Plant</div>
          </Tooltip>
        ),
        enableEditing: false,
        Cell: ({ row }) => <SupplierList suppliers={(row.original.plant || '').split(',')} />,
      },
      {
        Header: ({ table }) => (
          <div style={{ minWidth: '150px' }} title=''>
            <Tooltip label='Printed Materials always require artwork'>
              <span className='pointer'> Is Artwork Required?</span>
            </Tooltip>
          </div>
        ),
        enableEditing: true,
        editVariant: 'select',
        mantineEditSelectProps: {
          data: ['Yes', 'No'],
        },
        header: 'Is Artwork Required?',
        accessorKey: 'artwork_required',
        Cell: ({ row }) => {
          const { artwork_required, material_code, scope_id, plant } = row.original;
          const value = pmoApproval
            ? getValuesFromD2p(material_code, 'artwork_actually_needed')
            : artwork_required;
          const error = errors[`${scope_id}_${plant}`]?.artwork_required;
          return isSubmissionReview ? (
            <PreviewColumnValue value={value} error={error} />
          ) : (
            <Artwork
              onChange={(event) => handleChange(event, 'artwork_required', row)}
              value={value}
              error={error}
            />
          );
        },
      },
      {
        header: 'Is Artwork Adaptation Required?',
        enableEditing: true,
        editVariant: 'select',
        mantineEditSelectProps: {
          data: ['Yes', 'No'],
        },
        Header: ({ table }) => (
          <div style={{ minWidth: '150px' }} title=''>
            <Tooltip label='Existing artwork needs to be adapted. Ex: Add a new legal change'>
              <span className='pointer'>Is Artwork Adaptation Required?</span>
            </Tooltip>
          </div>
        ),
        accessorKey: 'artwork_adaption',
        Cell: ({ row }) => {
          const { artwork_required, artwork_adaption, material_code, scope_id, plant } =
            row.original;
          const value = pmoApproval
            ? getValuesFromD2p(material_code, 'artwork_adaption_actually_needed')
            : artwork_adaption;
          const error = errors[`${scope_id}_${plant}`]?.artwork_adaption;

          return isSubmissionReview ? (
            <PreviewColumnValue value={value} error={error} />
          ) : (
            <div className='custom-multi-select'>
              <ArtworkAdaptation
                name='artwork_adaption'
                value={value}
                onChange={(event) => handleChange(event, 'artwork_adaption', row)}
                disabled={isSubmissionReview || artwork_required === 'No'}
                error={error}
              />
            </div>
          );
        },
      },
      {
        header: 'Artwork Management',
        enableEditing: true,
        editVariant: 'select',
        mantineEditSelectProps: {
          data: [
            'Text change only',
            'Design or technical specs change, with new colors',
            'Design or technical specs change, with existing colors',
            'Others',
          ],
        },
        Header: ({ table }) => {
          return (
            <div title=''>
              <Tooltip label='Artwork Management'>
                <span className='pointer'>Artwork Management</span>
              </Tooltip>
            </div>
          );
        },
        accessorKey: 'artwork_change',
        Cell: ({ row }) => {
          const { scope_id, plant } = row.original;
          const error = errors[`${scope_id}_${plant}`]?.artwork_change;

          return isSubmissionReview ? (
            <PreviewColumnValue value={row.original.artwork_change} error={error} />
          ) : (
            <div className='custom-multi-select'>
              <ArtworkManagement
                name='artwork_change'
                value={row.original.artwork_change}
                onChange={(event) => handleChange(event, 'artwork_change', row)}
                disabled={
                  isSubmissionReview ||
                  row.original.artwork_required === 'No' ||
                  row.original.artwork_adaption === 'No'
                }
                error={error}
              />
            </div>
          );
        },
      },
      {
        header: 'Type of Supplier Change',
        enableEditing: true,
        editVariant: 'select',
        mantineEditSelectProps: {
          data: ['Supplier Switch', 'Dual Sourcing'],
        },
        Header: ({ table }) => (
          <div style={{ minWidth: '180px' }} title=''>
            <Tooltip label='Type of Supplier Change'>
              <span className='pointer'>Type of Supplier Change</span>
            </Tooltip>
          </div>
        ),
        accessorKey: 'supplier_change_type',
        Cell: ({ row }) => {
          const { scope_id, plant } = row.original;
          const error = errors[`${scope_id}_${plant}`]?.supplier_change_type;
          return isSubmissionReview ? (
            <PreviewColumnValue value={row.original.supplier_change_type} error={error} />
          ) : (
            <SupplierChange
              name='supplier_change_type'
              value={row.original.supplier_change_type}
              onChange={(event) => handleChange(event, 'supplier_change_type', row)}
              disabled={isSubmissionReview}
              error={error}
            />
          );
        },
      },
      {
        header: 'Type of Changeover',
        enableEditing: true,
        editVariant: 'select',
        mantineEditSelectProps: {
          data: changeoverOptions,
        },
        Header: ({ table }) => (
          <div style={{ marginBottom: '2px' }} title=''>
            <Tooltip label='Type of Changeover'>
              <span className='pointer'>Type of Changeover</span>
            </Tooltip>
          </div>
        ),
        accessorKey: 'changeover_type',
        Cell: ({ row, table }) => {
          const { scope_id, plant } = row.original;
          const error = errors[`${scope_id}_${plant}`]?.changeover_type;

          return isSubmissionReview ? (
            <PreviewColumnValue value={row.original.changeover_type} error={error} />
          ) : (
            <Changeover
              name='changeover_type'
              value={row.original.changeover_type || null}
              onChange={(event) => {
                if (event === materialchangeOverOptions.mixChange && !mixChangeOverEligibility()) {
                  setMixChangeOverAlert(true);
                } else {
                  handleChange(event, 'changeover_type', row, table.getFilteredSelectedRowModel());
                }
              }}
              disabled={isSubmissionReview}
              error={error}
              data={changeoverOptions}
            />
          );
        },
      },

      {
        header: 'Leading material',
        Header: () => (
          <div title=''>
            <Tooltip label='Leading material'>
              <span className='pointer'> Leading material</span>
            </Tooltip>
          </div>
        ),
        accessorKey: 'mix_changeover',
        Cell: ({ row, table }) => {
          const { scope_id, plant } = row.original;
          const error = errors[`${scope_id}_${plant}`]?.mix_changeover;
          const options = getMixChangeOverOptions();
          return isSubmissionReview ? (
            <PreviewColumnValue value={row.original.mix_changeover} error={error} />
          ) : row.original.changeover_type === materialchangeOverOptions.mixChange &&
            mixChangeOverEligibility() ? (
            <Select
              size='sm'
              radius='md'
              value={row.original.mix_changeover}
              onChange={(event) =>
                handleMixOverChange(
                  event,
                  row,
                  'mix_changeover',
                  table.getFilteredSelectedRowModel(),
                  table.getIsAllRowsSelected(),
                )
              }
              error={error}
              placeholder='Select'
              searchable
              disabled={isSubmissionReview}
              data={(options || []).map((option) => String(option))}
            />
          ) : (
            ''
          );
        },
      },
      {
        header: 'Changeover Date',
        enableEditing: true,
        editVariant: 'datepicker',
        mantineEditSelectProps: {
          data: { minDate: getMinChangeOverDate(), initialMonth: getMinChangeOverDate() },
        },
        Header: ({ table }) => (
          <div title=''>
            <Tooltip label='Changeover Date'>
              <span className='pointer'>Changeover Date</span>
            </Tooltip>
          </div>
        ),
        accessorKey: 'changeover_date',
        Cell: ({ row }) => {
          const { scope_id, plant } = row.original;
          const error = errors[`${scope_id}_${plant}`]?.changeover_date;
          return isSubmissionReview ? (
            <PreviewColumnValue value={row.original.changeover_date} error={error} />
          ) : (
            <ChangeoverDate
              name='changeover_date'
              value={row.original.changeover_date}
              onChange={(event) => handleChange(event, 'changeover_date', row)}
              disabled={
                isSubmissionReview ||
                (row.original.changeover_type &&
                  row.original.changeover_type === materialchangeOverOptions.softChange) ||
                row.original.changeover_type === materialchangeOverOptions.mixChange
              }
              error={error}
              minDate={getMinChangeOverDate()}
              initialMonth={getMinChangeOverDate()}
            />
          );
        },
      },
      {
        header: 'Current Suppliers',
        Header: ({ table }) => (
          <Tooltip label='Current Suppliers'>
            <div title=''>Current Suppliers</div>
          </Tooltip>
        ),
        accessorKey: 'current_suppliers',
        Cell: ({ row }) => (
          <div>
            <SupplierList suppliers={(row.original.current_suppliers || '').split(',')} />
          </div>
        ),
      },
      {
        header: 'To be Suppliers',
        editVariant: 'button',
        enableEditing: true,
        mantineEditSelectProps: {
          action: () => {
            setStatus({ open: true, id: 'all' });
          },
        },
        Header: ({ table, row }) => (
          <div>
            <Tooltip label='To be Suppliers'>
              <div title=''>To be Suppliers</div>
            </Tooltip>
          </div>
        ),
        id: 'to_be_supplier',
        Cell: ({ row }) => {
          const error = errors[`${row.original.scope_id}_${row.original.plant}`]?.to_be_supplier;
          return (
            <>
              {!isSubmissionReview && (
                <Button
                  onClick={() => setStatus({ open: true, id: row.original.scope_id })}
                  variant='gradient'
                  color='primary'
                  style={{
                    background: 'black',
                    color: error ? 'red' : 'white',
                  }}
                  fullWidth
                  size='sm'
                  radius='sm'
                  className='add-supplier-button'
                >
                  ADD
                </Button>
              )}

              {error && isSubmissionReview ? (
                <PreviewColumnValue error={error} />
              ) : (
                <SupplierList suppliers={row.original.to_be_supplier} />
              )}
            </>
          );
        },
      },
      {
        header: 'Comment',
        enableEditing: true,
        editVariant: 'text',
        Header: ({ table }) => (
          <div title=''>
            <Tooltip label='Comment'>
              <span className='pointer'>Comment</span>
            </Tooltip>
          </div>
        ),
        accessorKey: 'comment',
        Cell: ({ row }) => {
          const { scope_id, plant } = row.original;
          const error = errors[`${scope_id}_${plant}`]?.comment;
          return isSubmissionReview ? (
            <PreviewColumnValue value={row.original.comment} error={error} />
          ) : (
            <Comment
              disabled={isSubmissionReview}
              name='comment'
              value={row.original.comment}
              onChange={(event) => handleChange(event, 'comment', row)}
              error={error ? error : false}
            />
          );
        },
      },
    ],
    [errors, activePlants],
  );

  return (
    <>
      <MantineTable
        columns={columns}
        initialData={supplierForm}
        unique={'scope_id'}
        handleRowSelection={handleRowSelection}
        activeRows={activePlants}
        enableRowSelection={!isSubmissionReview}
        loading={plantsLoading}
        resetTableData={resetTableData}
        editAll={!isSubmissionReview}
        applyToAll={handleDefaultSet}
        showSelectedToggle={!isSubmissionReview}
        showResetAll={!isSubmissionReview}
        hideSelectColumn={isSubmissionReview}
        alwaysShowEditAll={!isSubmissionReview}
      />
      {status.open && (
        <Suppliers
          data={suppliers}
          status={status}
          setStatus={setStatus}
          onChange={handleSupplierSelect}
          isSubmissionReview={isSubmissionReview}
        />
      )}

      {mixChangeOverAlert && (
        <MixoverChangeAlert
          mixChangeOverAlert={mixChangeOverAlert}
          setMixChangeOverAlert={setMixChangeOverAlert}
        />
      )}
    </>
  );
};
