import { Button, Container, Grid, Group, Modal, Text } from '@mantine/core';
import { saveAs } from 'file-saver';
import React from 'react';
import { EXTENSIONS } from '../../../../constants';
import { axios } from '../../../../utils/axios';

export const PriorityModal = ({ priorityModalOpen, setPriorityModalOpen }) => {
  const downloadFile = (
    filename = 'Fast Track for RFS Tool.pdf',
    container = 'stm-download-files',
  ) => {
    axios.get(`/costing-request/download/${filename}/${container}`).then(
      (res) => {
        const base64String = res.data.data.downloaded;
        const ext = filename.split('.');
        saveAs(`${EXTENSIONS[ext[ext.length - 1].toLowerCase()]}${base64String}`, filename);
      },
      (error) => {
        console.log(error);
      },
    );
  };

  return (
    <Modal
      opened={priorityModalOpen}
      onClose={() => setPriorityModalOpen(false)}
      title='When to Request Critical?'
      centered
    >
      <Container>
        <Grid>
          <Grid.Col span={12}>
            <Group noWrap className='confirm-modal-group'>
              <Text size='sm'>
                You are about to download the document to understand what does Critical/Non-Critical
                urgency mean.
                <br />
                Please select your urgency based on priority matrix.
              </Text>
            </Group>
          </Grid.Col>

          <Grid.Col span={4} offset={8}>
            <Button
              variant='gradient'
              onClick={() => downloadFile('Fast Track Document_Costing_TP.pptx')}
              fullWidth
            >
              Download
            </Button>
          </Grid.Col>
        </Grid>
      </Container>
    </Modal>
  );
};
