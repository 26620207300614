import { useParams, useSearchParams } from 'react-router-dom';
import { Space, Tabs } from '@mantine/core';
import { Overview } from './overview';
import { BomCreation } from './bomCreation';
import { PvLine } from './pvLine';
import { tabStyles } from '../../../utils/helpers';
import RfsLayout from '../../../components/layouts/RfsLayout';
import ContentPaper from '../../../components/layouts/rfs/Content';
import { useContext, useEffect, useState } from 'react';
import { CostingRequestsContext } from '../../../contexts/CostingRequest';
import { AppLoader } from '../../../components/common/loader';
import { ProjectOverview } from './projectOverview';
import { Logs } from './logs';
import { BomComponentPrice } from './bomComponentPrice';
import { NormsCheck } from './normsCheck';
import { BudgetBomAdjustment } from './budgetBomAdjustment';
import { ReferenceSKU } from './referenceSKU';
import Vic from './vic';
import VicApproval from './vicApproval';

const tabs = [
  {
    name: 'Overview',
    value: 'overview',
    validator: 'Overview',
    component: (props) => <Overview {...props} />,
  },

  {
    name: 'Costing BOM Creation Check',
    value: 'bomCreation',
    validator: 'Costing Bom Creation Check',
    component: (props) => <BomCreation {...props} />,
  },
  {
    name: 'PV Line Item Check',
    value: 'pvLine',
    validator: 'PV Line Item Check',
    component: (props) => <PvLine {...props} />,
  },
  {
    name: 'BOM Component Price',
    value: 'bomComponentPrice',
    validator: 'BOM Component Price',
    component: (props) => <BomComponentPrice {...props} />,
  },
  {
    name: 'Reference SKU Selection',
    value: 'referenceSKUSelection',
    validator: 'Reference Sku Selection',
    component: (props) => <ReferenceSKU {...props} />,
  },
  {
    name: 'Budget BOM Adjustment',
    value: 'budgetBomAdjustment',
    validator: 'Budget Bom Adjustment',
    component: (props) => <BudgetBomAdjustment {...props} />,
  },

  {
    name: 'NORM Adjustment',
    value: 'normAdjustment',
    validator: 'NORM Adjustment',
    component: (props) => <NormsCheck {...props} />,
  },
  {
    name: 'VIC Submission',
    value: 'vicSubmission',
    validator: 'VIC Submission',
    component: (props) => <Vic {...props} />,
  },
  {
    name: 'VIC Approval',
    value: 'vicApproval',
    validator: 'VIC Approval',
    component: (props) => <VicApproval {...props} />,
  },
];
export const SubmittedRequest = () => {
  const { crNumber } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getRequestDetails, loaders, tabsPermissions, visibleTabs, getStepStatus, tabsLoader } =
    useContext(CostingRequestsContext);
  const [logsModal, setLogsModal] = useState({ open: false, logs: [] });

  useEffect(() => {
    getStepStatus(crNumber);
    tabsPermissions(crNumber);
    getRequestDetails(crNumber);
  }, [crNumber]);

  if (loaders.info || tabsLoader) {
    return (
      <RfsLayout>
        <ContentPaper>
          <AppLoader center size='md' />
        </ContentPaper>
      </RfsLayout>
    );
  }

  return (
    <RfsLayout>
      <Tabs
        keepMounted={false}
        value={searchParams.get('tab') || 'overview'}
        onTabChange={(value) => setSearchParams({ tab: value })}
        variant='unstyled'
        styles={tabStyles}
        className='prod-dev-tabs'
        color='#e5b611'
      >
        <Tabs.List>
          {tabs.map(
            (tab, index) =>
              (visibleTabs.includes(tab.validator) || !tab.validator) && (
                <Tabs.Tab value={tab.value} key={`submitted_${index}`} className='menu-tabs'>
                  {tab.name}
                </Tabs.Tab>
              ),
          )}
        </Tabs.List>

        {tabs.map(
          (tab, index) =>
            (visibleTabs.includes(tab.validator) || !tab.validator) && (
              <Tabs.Panel value={tab.value} key={`submitted_content_${index}`}>
                {[
                  'overview',
                  'referenceSKUSelection',
                  'budgetBomAdjustment',
                  'vicSubmission',
                  'normAdjustment',
                  'vicApproval',
                ].includes(tab.value) ? (
                  tab.component({
                    logsModal,
                    setLogsModal,
                    activeTab: searchParams.get('tab'),
                  })
                ) : (
                  <ContentPaper footer={null}>
                    <ProjectOverview />
                    <Space h='sm' />
                    {tab.component({
                      logsModal,
                      setLogsModal,
                      activeTab: searchParams.get('tab'),
                      stepStatusKey: tab.validator,
                    })}
                  </ContentPaper>
                )}
              </Tabs.Panel>
            ),
        )}
      </Tabs>

      {logsModal.open && <Logs logsModal={logsModal} setLogsModal={setLogsModal} />}
    </RfsLayout>
  );
};
