import React, { useState, useEffect } from 'react';
import { Modal } from '@mantine/core';
import withConfirm from '../../../components/common/confirm-dialog';
import { EditModalContainer } from './styles/editModal';
import { ListedComponent } from './ListedComp';

const RoleModal = ({ state, close, confirm, setConfirmLoading, fetchUsers }) => {
    const [selectedRoles, setSelectedRoles] = useState([]);
  
    return (
      <Modal 
        opened={state.open} 
        onClose={close} 
        title={'List of Accesses a User has!'} 
        centered 
        size="lg"
      >
        <EditModalContainer>
          <div style={{   }}>
            <ListedComponent
              data={state?.user?.int_user_role_mappings || []}
              selectedRoles={selectedRoles}
              setSelectedRoles={setSelectedRoles}
            />
          </div>
        </EditModalContainer>
      </Modal>
    );
  };
  
  export default withConfirm(RoleModal);
  