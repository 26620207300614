import React, { useState, useEffect } from 'react';
import ContentPaper from '../../../components/layouts/rfs/Content';
import { Link } from 'react-router-dom';
import { Card, Modal, Container, Row } from 'react-bootstrap';
import { CircleX, ColumnsOff, InfoCircle } from 'tabler-icons-react';
import { UploadIcon } from '@radix-ui/react-icons';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import '../../RaiseTicket/TicketPage.css';
import { Textarea, Group, Button, Select, Tooltip, ActionIcon, Text, Grid } from '@mantine/core';
import { useForm } from '@mantine/form';
import RfsLayout from '../../../components/layouts/RfsLayout';
import { AppLoader } from '../../../components/common/loader';
var SYS_ID;
var INC_NUM;

const RaiseTicketCostReq = () => {
  const { instance, accounts } = useMsal();
  const [issueType, setIssueType] = useState('');
  const [queryType, setQueryType] = useState('');
  const [typeofRfs, settypeofRfs] = useState('');
  const [files, setFiles] = useState('');
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [showProgress, setShowProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileError, setFileError] = useState({});
  const [issueDisable, setIssueDisable] = useState(false);
  const [errors, setErrors] = useState({
    queryType: false,
    issueType: false,
    TypeofRfs: false,
    shortDescription: false,
    issueDescription: false,
  });

  const form = useForm({
    initialValues: {
      shortDescription: '',
      issueDescription: '',
    },
    validate: {
      shortDescription: (value) => value.trim().length === 0,
      issueDescription: (value) => value.trim().length === 0,
    },
  });

  async function getSysID() {
    const config = {
      headers: { Authorization: `${localStorage.getItem('snow_api_access_token')}` },
    };
    axios
      .get(`${process.env.REACT_APP_SNOW_GET_INCIDENT_SYS_ID_URL}${accounts[0].username}`, config)
      .then((response) => {
        SYS_ID = response.data.result[0].sys_id;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    getSysID();
  }, []);

  const removeAttachment = () => {
    setFiles('');
    setFileError({});
  };

  const handleSubmit = (e) => {
    setShowProgress(true);
    e.preventDefault();

    setLoading(true);

    const value = form.values;
    if (
      queryType === '' ||
      (issueType === '' && queryType === 'toolRelated') ||
      value.shortDescription === '' ||
      value.issueDescription === ''
    ) {
      setErrors({
        queryType: queryType === '' ? true : false,
        issueType: queryType === 'toolRelated' ? (issueType === '' ? true : false) : false,
        shortDescription: value.shortDescription === '' ? true : false,
        issueDescription: value.issueDescription === '' ? true : false,
      });
      setShowProgress(false);
      setLoading(false);
      return;
    }

    let data = {};

    data['sys_id'] = SYS_ID;
    data['username'] = accounts[0].username;
    data['issueDescription'] = value.issueDescription.substring(0, 500);
    data['shortDescription'] = value.shortDescription.substring(0, 150);
    data['projectName'] = 'Speed To Market';
    data['zone'] = 'EUR';
    data['priority'] = '3';
    data['issueType'] = 'u_performance';
    data['pointOfContact'] = accounts[0].username;
    data['assignmentGroup'] =
      queryType === 'toolRelated'
        ? '47259ddc97fda91071adb9b0f053af61'
        : 'e348b5e89782d65091423501f053af6c';
    data['typeOfSolution'] = 'PWA';
    data['businessService'] = '29dea5938785ed10063f628f8bbb35e3';
    async function uploadFile(data) {
      const formData = new FormData();
      formData.append('attachment', files[0]);
      formData.append('sys_id', data['IncidentSysId']);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('snow_api_access_token')}`,
        },
      };
      axios
        .post(process.env.REACT_APP_SNOW_UPLOAD_FILE_URL, formData, config)
        .then((response) => {});
    }

    async function getIncidentSysID(incidentNumber) {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('snow_api_access_token')}` },
      };
      axios
        .get(`${process.env.REACT_APP_SNOW_GET_INCIDENT_SYS_ID_URL}${incidentNumber}`, config)
        .then((res) => {
          data['IncidentSysId'] = res.data.result[0].sys_id;
          uploadFile(data);
        });
    }

    async function pushSQL(data) {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('snow_api_access_token')}` },
      };
      axios.post(process.env.REACT_APP_SNOW_PUSH_SQL_ID_URL, data, config).then((res) => {});
      setShowProgress(false);
    }

    async function raiseTicket(data) {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('snow_api_access_token')}` },
      };
      axios
        .post(process.env.REACT_APP_SNOW_CREATE_INCIDENTS, data, config)
        .then((res) => {
          data['IncidentNumber'] = res.data.result.number;
          INC_NUM = data['IncidentNumber'];
          if (files[0]?.name?.length > 0) getIncidentSysID(INC_NUM);
          pushSQL(data);
          setTimeout(() => {
            handleShow();
          }, 2000);
        })
        .catch((e) => {
          console.log(e);
          alert('Upload Error');
        });
    }

    raiseTicket(data);
  };

  const options = [
    {
      label: 'Incident',
      value: 'Incident',
    },
    {
      label: 'Change Request',
      value: 'Change Request',
    },
  ];

  const queryOptions = [
    {
      label: 'Process Related',
      value: 'processRelated',
    },
    {
      label: 'Tool Related',
      value: 'toolRelated',
    },
  ];
  const handleQueryTypeChange = (val) => {
    if (val === 'processRelated') {
      setIssueDisable(true);
      setIssueType(null);
      return;
    }
    setIssueDisable(false);
    return;
  };
  const fileSet = (e) => {
    const fileSize = e.target.files.item(0).size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb > 2) {
      setFileError({ error: 'Please select a file less than 2MB' });
      return false;
    } else {
      setFileError({});
    }

    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.zip|\.docx|\.xlsx|\.csv|\.pdf)$/i;
    if (!allowedExtensions.exec(e.target.value)) {
      setFileError({
        error:
          'Invalid file type, only .zip, .png , .jpeg, .jpg, .docx, .xlsx, .csv, .pdf are allowed',
      });
      e.target.value = '';
      return false;
    } else {
      setFileError(false);
    }

    setFiles(e.target.files);
  };

  return (
    <RfsLayout>
      <ContentPaper className='mainpage' page={'all-rfs'}>
        <Container>
          <form>
            <div
              xs={11}
              sm={12}
              md={11}
              lg={9}
              style={{ margin: 'auto', padding: '20px 9vw 0 9vw' }}
            >
              <Card style={{ padding: '20px 30px 30px 40px', width: '100%' }}>
                <Row>
                  <div className='list-group list-group-transparent mb-0'>
                    <Text
                      size='xl'
                      weight={780}
                      color='primary'
                      align='left'
                      style={{ fontSize: '30px', color: '#E5B611', paddingLeft: '10px' }}
                    >{`Raise Ticket`}</Text>
                  </div>
                </Row>

                <hr
                  style={{
                    color: 'black',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                  }}
                />
                <Row>
                  <div className='note' style={{ paddingBottom: '17px' }}>
                    <Text
                      className='noteBoxes type3'
                      size='md'
                      weight={600}
                      color='black'
                      align='left'
                    >{`Please fill the following details to create a support ticket on your queries. The team will get back to you shortly.`}</Text>
                  </div>
                </Row>
                <Row>
                  <div className='note' style={{ paddingBottom: '17px' }}>
                    <Text
                      className='noteBoxes type3'
                      size='md'
                      weight={500}
                      color='black'
                      align='left'
                    >
                      {` In case any process-related query is raised as a technical issue, the ticket will be closed without resolution.`}
                    </Text>
                  </div>
                </Row>
                <Row>
                  <div style={{ paddingBottom: '9px' }}>
                    <Text
                      size='md'
                      weight={500}
                      color={errors.queryType ? 'red' : 'black'}
                      align='left'
                    >
                      Type of Query<span style={{ color: 'red' }}>*</span>
                    </Text>
                  </div>
                </Row>
                <Row>
                  <div style={{ paddingBottom: '17px', width: '20vw', minWidth: '200px' }}>
                    <Select
                      placeholder='Select'
                      required
                      data={queryOptions}
                      name='queryType'
                      border={errors.queryType ? '2px solid red' : '2px'}
                      radius='14px'
                      value={queryType}
                      onChange={(value) => {
                        setQueryType(value);
                        handleQueryTypeChange(value);
                      }}
                      error={errors.issueType}
                    />
                  </div>
                </Row>
                <Row>
                  <div style={{ paddingBottom: '9px' }}>
                    <Text
                      size='md'
                      weight={500}
                      color={errors.issueType ? 'red' : 'black'}
                      align='left'
                    >
                      Type of Issue<span style={{ color: 'red' }}>*</span>
                    </Text>
                  </div>
                </Row>
                <Row>
                  <div style={{ paddingBottom: '17px', width: '20vw', minWidth: '200px' }}>
                    <Select
                      placeholder='Select'
                      required
                      data={options}
                      name='issueType'
                      border={errors.issueType ? '2px solid red' : '2px'}
                      radius='14px'
                      value={issueType}
                      onChange={setIssueType}
                      error={errors.issueType}
                      disabled={issueDisable}
                    />
                  </div>
                </Row>

                <Row></Row>
                <Row>
                  <div style={{ paddingBottom: '7px' }}>
                    <Text size='md' weight={500} color={'black'} align='left'>
                      Short Description<span style={{ color: 'red' }}>*</span>
                    </Text>
                  </div>
                </Row>
                <Row>
                  <div style={{ paddingBottom: '17px', width: '35vw', minWidth: '200px' }}>
                    <Textarea
                      mt='md'
                      required
                      placeholder='Enter Short Description (150 character limit)'
                      maxRows={10}
                      minRows={3}
                      autosize
                      name='shortDescription'
                      border={errors.issueType ? '2px solid red' : '2px'}
                      radius='14px'
                      style={{ marginTop: '0px' }}
                      {...form.getInputProps('shortDescription')}
                      error={errors.shortDescription}
                      value={form.values.shortDescription.substring(0, 150)}
                    />
                  </div>
                  {form.values.shortDescription.length > 149 && (
                    <div className='custom-mantine-Textarea-error ticketDescError'>
                      150 Character limit reached, exceeding character would be truncated on saving.
                    </div>
                  )}
                </Row>
                <Row>
                  <div style={{ paddingBottom: '7px' }}>
                    <Text
                      size='md'
                      weight={500}
                      color={errors.issueDescription ? 'red' : 'black'}
                      align='left'
                    >
                      Detailed Description<span style={{ color: 'red' }}>*</span>
                    </Text>
                  </div>
                </Row>
                <Row>
                  <div style={{ paddingBottom: '17px', width: '35vw', minWidth: '200px' }}>
                    <Textarea
                      mt='md'
                      required
                      placeholder='Enter Detailed Description (500 character limit)'
                      maxRows={10}
                      minRows={3}
                      autosize
                      name='issueDescription'
                      border={errors.issueType ? '2px solid red' : '2px'}
                      radius='14px'
                      style={{ marginTop: '0px' }}
                      {...form.getInputProps('issueDescription')}
                      error={errors.issueDescription}
                      value={form.values.issueDescription.substring(0, 500)}
                    />
                  </div>
                  {form.values.issueDescription.length > 499 && (
                    <div className='custom-mantine-Textarea-error ticketDescError'>
                      500 Character limit reached, exceeding character would be truncated on saving.
                    </div>
                  )}
                </Row>

                <Row>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'left',
                      flexWrap: 'wrap',
                    }}
                  >
                    <div style={{ paddingBottom: '7px' }}>
                      <Text size='md' weight={500} color='black' align='left'>{`Attachment `}</Text>
                    </div>
                    <div>
                      <Tooltip
                        multiline
                        maxWidth={250}
                        withArrow
                        transitionProps={{ transition: 'fade', duration: 200 }}
                        label={
                          <div>
                            <div>Provide relevant error screenshots regarding your query.</div>{' '}
                            <div>For multiple attachments please provide the zip folder.</div>
                          </div>
                        }
                      >
                        <ActionIcon>
                          <InfoCircle size={18} strokeWidth={2} color={'#e5b611'} />
                        </ActionIcon>
                      </Tooltip>
                    </div>
                    <div>
                      <Row>
                        <Text
                          noWrap
                          size='11px'
                          weight={400}
                          color='black'
                          align='left'
                          style={{ paddingTop: '2px' }}
                        >{`(Strictly allowed File types : .zip, .png , .jpeg, .jpg, .docx, .xlsx, .csv, .pdf)`}</Text>
                      </Row>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className='fileWrapper'>
                    <div style={{ width: '17vw' }}>
                      <input
                        type='file'
                        className='form-control'
                        id='mp-attachment'
                        multiple={false}
                        style={{ display: 'none' }}
                        accept='image/png,image/jpeg,application/pdf,text/csv,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/zip'
                        onChange={(e) => fileSet(e)}
                      />
                      <div className='upload-sec' style={{ minWidth: '250px' }}>
                        <div style={{ paddingRight: '15px' }}>
                          <Grid>
                            <Grid.Col span={8}>
                              <label>Choose Files</label>
                              <div
                                style={{
                                  maxWidth: 150,
                                  fontWeight: 600,
                                  fontSize: 10,
                                  paddingBottom: '3px',
                                }}
                              >
                                (Max-Size: 2MB){' '}
                              </div>
                            </Grid.Col>
                            <Grid.Col span={4}>
                              <label htmlFor='mp-attachment' id='uploadEvidencebtn'>
                                <Button
                                  className='icon-button'
                                  variant='contained'
                                  color='primary'
                                  component='span'
                                >
                                  <UploadIcon />
                                </Button>
                              </label>
                            </Grid.Col>
                          </Grid>
                        </div>
                      </div>
                    </div>

                    <div className='attachment-links d-flex'>
                      {files[0]?.name ? (
                        <div className='alert alert-success mb-0'>{files[0]?.name}</div>
                      ) : (
                        <div>{files[0]?.fileName}</div>
                      )}
                      {files[0]?.name ? (
                        <CircleX className='close-icon' onClick={removeAttachment}></CircleX>
                      ) : (
                        fileError.error &&
                        fileError.error !== '' && (
                          <span className='alert alert-danger d-inline-block mb-0'>
                            {fileError.error}
                          </span>
                        )
                      )}
                    </div>
                  </div>
                </Row>
                <Group position='right' mt='xl'>
                  <Link to='/'>
                    <Button
                      size='md'
                      radius='xl'
                      style={{
                        height: '36px',
                        borderRadius: '14px',
                        backgroundColor: 'red',
                        boxShadow:
                          'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
                      }}
                    >
                      Cancel
                    </Button>
                  </Link>
                  <Button
                    type='submit'
                    size='md'
                    radius='xl'
                    variant='gradient'
                    gradient={{ from: '#e3af32', to: '#f4e00f' }}
                    style={{
                      height: '36px',
                      borderRadius: '14px',
                      boxShadow:
                        'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
                    }}
                    onClick={handleSubmit}
                  >
                    {loading ? <AppLoader /> : 'Submit'}
                  </Button>
                </Group>
              </Card>
            </div>
          </form>

          <Modal show={showProgress} backdrop='static' keyboard={false} position='center'>
            <Modal.Header>
              <Modal.Title>Thank You!</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              Please wait we are communicating your query to SNOW team.
              <AppLoader
                size='lg'
                style={{
                  margin: 'auto',
                  display: 'block',
                  paddingTop: '2rem',
                }}
              />
            </Modal.Body>
          </Modal>

          <Modal
            show={show}
            onHide={handleClose}
            backdrop='static'
            keyboard={false}
            position='center'
          >
            <Modal.Header>
              <Modal.Title>Note</Modal.Title>
              <Link to='/'>{/* <IconX color='black' /> */}</Link>
            </Modal.Header>
            <Modal.Body text-align='center'>
              Your ticket has been raised with Incident Number: {INC_NUM} On Clicking Close, you
              will be redirected to Home page.
            </Modal.Body>
            <Modal.Footer>
              <Link to='/costingRequests/home'>
                <Button
                  leftIcon={<CircleX size={16} color='white' />}
                  style={{ backgroundColor: 'red', borderRadius: '14px', color: 'white' }}
                  radius='xl'
                >
                  Close
                </Button>
              </Link>
            </Modal.Footer>
          </Modal>
        </Container>
      </ContentPaper>
    </RfsLayout>
  );
};

export default RaiseTicketCostReq;
