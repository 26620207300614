import { useContext, useEffect, useMemo, useState } from 'react';
import MantineTable from '../../../common/table/mantine';
import { AdminContext } from '../../../contexts/AdminContext';
import { Tooltip, ActionIcon } from '@mantine/core';
import EditModal from './editModal';
import { IconEdit } from '@tabler/icons-react';
import RoleModal from './roleAccess';

export const Users = ({ activeTab }) => {
  const [editModalState, setModalState] = useState({ open: false, user: null });
  const [roleModalState, setRoleModalState] = useState({ open: false, user: null });
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { fetchUsersFromBackend } = useContext(AdminContext);

  useEffect(() => {
    if (activeTab === 'existing') fetch();
  }, [activeTab]);

  const fetch = async () => {
    setLoading(true);
    try {
      const transformedData = await fetchUsersFromBackend();
      setUsers(transformedData);
    } catch (e) {
      console.error('Error fetching data:', e);
    } finally {
      setLoading(false);
    }
  };

  const existingUsersColumns = useMemo(
    () => [
      {
        header: 'Actions',
        id: 'actions',
        enableColumnFilter: false,
        enableColumnSorting: false,
        enableColumnActions: false,
        size: 80,
        minSize: 80,
        maxSize: 120,
        Cell: ({ row }) => (
          <Tooltip label='Edit'>
            <ActionIcon onClick={() => setModalState({ open: true, user: row.original })}>
              <IconEdit />
            </ActionIcon>
          </Tooltip>
        ),
      },
      {
        header: 'Name',
        accessorKey: 'name',
        enableEditing: false,
      },
      {
        header: 'Email',
        accessorKey: 'email',
        enableEditing: false,
      },
      {
        header: 'Roles',
        accessorKey: 'roles',
        enableEditing: true,
        Cell: ({ row }) => (
          <Tooltip label="Click to see assigned roles in detail">
            <div
              style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
              onClick={() => setRoleModalState({ open: true, user: row.original })}
            >
              {row.original.roles}
            </div>
          </Tooltip>
        ),
      },
      {
        header: 'Created on',
        accessorKey: 'created_on',
        enableEditing: false,
      },
      {
        header: 'Created by',
        accessorKey: 'createdBy',
        enableEditing: false,
      },
      {
        header: 'Approved by',
        accessorKey: 'approvedBy',
        enableEditing: false,
      },
    ],
    [users],
  );

  return (
    <>
      {' '}
      <MantineTable
        columns={existingUsersColumns}
        initialData={users}
        unique={'id'}
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        csvFilename={`existing-users`}
        pageSize={10}
        enableExport={true}
        initialState={{
          sorting: [
            {
              id: 'name',
              desc: false,
            },
          ],
        }}
      />
      {editModalState.open && (
        <EditModal
          state={editModalState}
          fetchUsers={fetch}
          close={() => setModalState({ open: false, user: null })}
        />
      )}
       {roleModalState.open && (
        <RoleModal
          state={roleModalState}
          fetchUsers={fetch}
          close={() => setRoleModalState({ open: false, user: null })}
        />
      )}
    </>
  );
};
