import React, { useState, useMemo } from 'react';
import RfsLayout from '../../components/layouts/RfsLayout';
import ContentPaper from '../../components/layouts/rfs/Content';
import { Row, Col } from 'react-bootstrap';
import { Button, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import BottleneckReport from '../../components/report/bottleneckReport';
import moment from 'moment';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import './reports.css';
import { dateFormat } from '../../constants';

const getClassName = (start, due, sla, type = null) => {
  const startDate = start ? moment(start) : null;
  const dueDate = due ? moment(due) : null;
  const slaDate = sla ? moment(sla) : null;

  let className = '';

  if (startDate && !dueDate) className = 'cell-option-1';
  else if (startDate && dueDate && startDate.isBefore(slaDate)) className = 'cell-option-2';
  else if (startDate && dueDate && startDate.isAfter(slaDate)) className = 'cell-option-3';

  return className;
};

function stringToBullets(commaSeparatedString) {
  if (!commaSeparatedString) {
    return null;
  }

  const items = commaSeparatedString?.split(',').map((item) => item.trim());

  if (items?.length === 0 || items[0] === '') return null;

  return (
    <ul>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
}
const BottleneckReportPage = () => {
  const navigate = useNavigate();

  const [queryClient] = React.useState(() => new QueryClient());

  const columns = useMemo(
    () => [
      {
        id: 'rfs_details', //id used to define `group` column
        header: 'RFS Details',
        columns: [
          {
            header: 'RFS ID',
            accessorKey: `rfs_id`,
            enableEditing: false,
          },
          {
            header: 'RFS Name',
            accessorKey: 'rfs_name',
            enableEditing: false,
          },
        ],
      },
      {
        header: '',
        id: 'rfs_status',
        columns: [
          {
            header: 'RFS Status',
            accessorKey: 'rfs_status',
            enableEditing: false,
          },
        ],
      },
      {
        header: '',
        id: 'rfs_type',
        columns: [
          {
            header: 'RFS Type',
            accessorKey: 'rfs_type',
            enableEditing: false,
          },
        ],
      },
      {
        header: '',
        id: 'fast_track',
        columns: [
          {
            header: 'Fast Track',
            accessorKey: 'fast_track',
            enableEditing: false,
          },
        ],
      },
      {
        header: '',
        id: 'fsd',
        columns: [
          {
            header: 'First Shipment Date',
            accessorKey: 'FSD',
            enableEditing: false,
            Cell: ({ row, renderedCellValue }) =>
              renderedCellValue ? moment(renderedCellValue).format(dateFormat) : 'N/A',
          },
        ],
      },
      {
        header: '',
        id: 'submission_date',
        columns: [
          {
            header: 'Submission Date',
            accessorKey: 'submission_date',
            enableEditing: false,
            Cell: ({ renderedCellValue, row }) => (
              <Text>
                {renderedCellValue ? moment(renderedCellValue).format(dateFormat) : 'N/A'}
              </Text>
            ),
          },
        ],
      },

      {
        header: '',
        id: 'gatekeeper',
        columns: [
          {
            header: 'Gatekeeper',
            accessorKey: 'gatekeeper',
            enableEditing: false,
          },
        ],
      },

      {
        header: '',
        id: 'prod_bus_unit',
        columns: [
          {
            header: 'Production Business Unit',
            accessorKey: 'ProductionBusinessUnit',
            enableEditing: false,
          },
        ],
      },
      {
        header: '',
        id: 'sales_bus_unit',
        columns: [
          {
            header: 'Sales Business Unit',
            accessorKey: 'sales_business_unit',
            enableEditing: false,
          },
        ],
      },
      {
        header: '',
        id: 'plants',
        columns: [
          {
            header: 'Plants',
            accessorKey: 'Plants',
            enableEditing: false,
          },
        ],
      },
      {
        header: '',
        id: 'markets',
        columns: [
          {
            header: 'Markets',
            accessorKey: 'Markets',
            enableEditing: false,
          },
        ],
      },
      {
        header: '',
        id: 'sku_rfs_vol',
        columns: [
          {
            header: 'SKU RFS Volume',
            accessorKey: 'sku_rfs_volume',
            enableEditing: false,
          },
        ],
      },
      {
        header: '',
        id: 'rfs_vol',
        columns: [
          {
            header: 'RFS Volume',
            accessorKey: 'rfs_volume',
            enableEditing: false,
          },
        ],
      },
      {
        header: '',
        id: 'sku_number',
        columns: [
          {
            header: 'SKU Number',
            accessorKey: 'sku_number',
            enableEditing: false,
          },
        ],
      },
      {
        header: '',
        id: 'sku_desc',
        columns: [
          {
            header: 'SKU Description',
            accessorKey: 'sku_description',
            size: '300',
            enableEditing: false,
          },
        ],
      },
      {
        header: '',
        id: 'mat_num',
        columns: [
          {
            header: 'Material Number',
            accessorKey: 'material_number',
            enableEditing: false,
          },
        ],
      },
      {
        header: '',
        id: 'mat_desc',
        columns: [
          {
            header: 'Material Description',
            accessorKey: 'material_description',
            enableEditing: false,
          },
        ],
      },
      {
        header: '',
        id: 'mat_group',
        columns: [
          {
            header: 'Material Group',
            accessorKey: 'MaterialGroup',
            enableEditing: false,
          },
        ],
      },
      {
        header: '',
        id: 'steps',
        columns: [
          {
            header: 'Steps In Progress',
            accessorKey: 'StepsInProgress',
            size: '300',
            enableEditing: false,
            Cell: ({ renderedCellValue, row }) => stringToBullets(row.original.StepsInProgress),
          },
        ],
      },
      {
        header: 'SKU Creation',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate1',
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate1
                  ? moment(row.original.StartDate1).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate1',
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate1 ? moment(row.original.DueDate1).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee1',
          },
        ],
      },
      {
        header: 'Supplier Name',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate2',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate2,
                row.original.supplierNameSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate2
                  ? moment(row.original.StartDate2).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate2',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate2,
                cell.getValue(),
                row.original.supplierNameSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate2 ? moment(row.original.DueDate2).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee2',
          },
        ],
      },
      {
        header: 'SKU Status 40',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate3',
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate3
                  ? moment(row.original.StartDate3).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate3',
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate3 ? moment(row.original.DueDate3).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee3',
          },
        ],
      },
      {
        header: 'Demand Planning',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate4',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate4,
                row.original.demandPlanningSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate4
                  ? moment(row.original.StartDate4).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate4',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate4,
                cell.getValue(),
                row.original.demandPlanningSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate4 ? moment(row.original.DueDate4).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee4',
          },
        ],
      },
      {
        header: 'Production Version',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate5',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate5,
                row.original.productionVersionSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate5
                  ? moment(row.original.StartDate5).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate5',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate5,
                cell.getValue(),
                row.original.productionVersionSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate5 ? moment(row.original.DueDate5).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee5',
          },
        ],
      },
      {
        header: 'Artwork Development',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate6',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate6,
                row.original.artworkDevelopmentSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate6
                  ? moment(row.original.StartDate6).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate6',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate6,
                cell.getValue(),
                row.original.artworkDevelopmentSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate6 ? moment(row.original.DueDate6).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'EndDate6',
          },
        ],
      },
      {
        header: 'EAN Approval',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate7',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate7,
                row.original.eanApprovalSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate7
                  ? moment(row.original.StartDate7).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate7',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate7,
                cell.getValue(),
                row.original.eanApprovalSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate7 ? moment(row.original.DueDate7).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee7',
          },
        ],
      },
      {
        header: 'Materials Contract',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate8',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate8,
                row.original.materialContractsSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate8
                  ? moment(row.original.StartDate8).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate8',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate8,
                cell.getValue(),
                row.original.materialContractsSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate8 ? moment(row.original.DueDate8).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee8',
          },
        ],
      },
      {
        header: 'Standard Price',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate9',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate9,
                row.original.standardPriceSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate9
                  ? moment(row.original.StartDate9).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate9',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate9,
                cell.getValue(),
                row.original.standardPriceSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate9 ? moment(row.original.DueDate9).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee9',
          },
        ],
      },
      {
        header: 'Status 99',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate10',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate10,
                row.original.status99Sla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate10
                  ? moment(row.original.StartDate10).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate10',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate10,
                cell.getValue(),
                row.original.status99Sla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate10 ? moment(row.original.DueDate10).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee10',
          },
        ],
      },
      {
        header: 'Cost Collector',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate11',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate11,
                row.original.costCollectorSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate11
                  ? moment(row.original.StartDate11).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate11',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate11,
                cell.getValue(),
                row.original.costCollectorSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate11 ? moment(row.original.DueDate11).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee11',
          },
        ],
      },
      {
        header: 'Market Extension',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate12',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate12,
                row.original.marketExtensionSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate12
                  ? moment(row.original.StartDate12).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate12',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate12,
                cell.getValue(),
                row.original.marketExtensionSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate12 ? moment(row.original.DueDate12).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee12',
          },
        ],
      },
      {
        header: 'Vendor Open',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate13',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate13,
                row.original.vendorOpenSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate13
                  ? moment(row.original.StartDate13).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate13',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate13,
                cell.getValue(),
                row.original.vendorOpenSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate13 ? moment(row.original.DueDate13).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee13',
          },
        ],
      },
      {
        header: 'VIC Check',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate14',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate14,
                row.original.vicCheckSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate14
                  ? moment(row.original.StartDate14).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate14',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate14,
                cell.getValue(),
                row.original.vicCheckSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate14 ? moment(row.original.DueDate14).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee14',
          },
        ],
      },
      {
        header: 'VLC Check',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate15',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate15,
                row.original.vlcCheckSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate15
                  ? moment(row.original.StartDate15).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate15',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate15,
                cell.getValue(),
                row.original.vlcCheckSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate15 ? moment(row.original.DueDate15).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee15',
          },
        ],
      },
      {
        header: 'Transfer Price',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate16',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate16,
                row.original.transferPricingSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate16
                  ? moment(row.original.StartDate16).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate16',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate16,
                cell.getValue(),
                row.original.transferPricingSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate16 ? moment(row.original.DueDate16).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee16',
          },
        ],
      },
      {
        header: 'Material Code Creation',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate17',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate17,
                row.original.materialCodeCreationSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate17
                  ? moment(row.original.StartDate17).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate17',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate17,
                cell.getValue(),
                row.original.materialCodeCreationSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate17 ? moment(row.original.DueDate17).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee17',
          },
        ],
      },
      {
        header: 'Production Plant Extension',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate18',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate18,
                row.original.productionPlantExtensionSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate18
                  ? moment(row.original.StartDate18).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate18',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate18,
                cell.getValue(),
                row.original.productionPlantExtensionSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate18 ? moment(row.original.DueDate18).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee18',
          },
        ],
      },
      {
        header: 'Material Status Change',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate19',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate19,
                row.original.materialStatusChangeSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate19
                  ? moment(row.original.StartDate19).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate19',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate19,
                cell.getValue(),
                row.original.materialStatusChangeSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate19 ? moment(row.original.DueDate19).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee19',
          },
        ],
      },
      {
        header: 'SKU Planning',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate20',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate20,
                row.original.psSkuPlanningSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate20
                  ? moment(row.original.StartDate20).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate20',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate20,
                cell.getValue(),
                row.original.psSkuPlanningSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate20 ? moment(row.original.DueDate20).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee20',
          },
        ],
      },
      {
        header: 'Material Pricing',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate21',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate21,
                row.original.materialPricingSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate21
                  ? moment(row.original.StartDate21).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate21',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate21,
                cell.getValue(),
                row.original.materialPricingSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate21 ? moment(row.original.DueDate21).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee21',
          },
        ],
      },
      {
        header: 'Supplier Production',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate22',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate22,
                row.original.supplierProductionSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate22
                  ? moment(row.original.StartDate22).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate22',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate22,
                cell.getValue(),
                row.original.supplierProductionSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate22 ? moment(row.original.DueDate22).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee22',
          },
        ],
      },
      {
        header: 'Material on Stock at Supplier',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate23',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate23,
                row.original.matOnStockSupplierSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate23
                  ? moment(row.original.StartDate23).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate23',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate23,
                cell.getValue(),
                row.original.matOnStockSupplierSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate23 ? moment(row.original.DueDate23).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee23',
          },
        ],
      },
      {
        header: 'Material on Stock at ABI',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate24',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate24,
                row.original.matOnStockABISla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate24
                  ? moment(row.original.StartDate24).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate24',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate24,
                cell.getValue(),
                row.original.matOnStockABISla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate24 ? moment(row.original.DueDate24).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee24',
          },
        ],
      },
      {
        header: 'BOM Availability',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate25',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate25,
                row.original.bomAvailabilitySla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate25
                  ? moment(row.original.StartDate25).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate25',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate25,
                cell.getValue(),
                row.original.bomAvailabilitySla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate25 ? moment(row.original.DueDate25).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee25',
          },
        ],
      },
      {
        header: 'Material at Status 99',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate26',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate26,
                row.original.materialAtStatus99Sla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate26
                  ? moment(row.original.StartDate26).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate26',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate26,
                row.original.materialAtStatus99Sla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate26 ? moment(row.original.DueDate26).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee26',
          },
        ],
      },
      {
        header: 'Procurement Price',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate27',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate27,
                row.original.procurementPriceSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate27
                  ? moment(row.original.StartDate27).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate27',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate27,
                cell.getValue(),
                row.original.procurementPriceSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate27 ? moment(row.original.DueDate27).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee27',
          },
        ],
      },
      {
        header: 'Repacking Fee',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate28',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate28,
                row.original.repackingFeeSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate28
                  ? moment(row.original.StartDate28).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate28',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate28,
                cell.getValue(),
                row.original.repackingFeeSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate28 ? moment(row.original.DueDate28).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee28',
          },
        ],
      },
      {
        header: 'Filling and Shipment',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate29',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate29,
                row.original.fillingAndShipmentSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate29
                  ? moment(row.original.StartDate29).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate29',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate29,
                cell.getValue(),
                row.original.fillingAndShipmentSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate29 ? moment(row.original.DueDate29).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee29',
          },
        ],
      },
      {
        header: 'Trials',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate30',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate30,
                row.original.trialsSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate30
                  ? moment(row.original.StartDate30).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate30',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate30,
                cell.getValue(),
                row.original.trialsSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate30 ? moment(row.original.DueDate30).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee30',
          },
        ],
      },
      {
        header: 'Status Check',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate31',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate31,
                row.original.statusCheckSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate31
                  ? moment(row.original.StartDate31).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate31',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate31,
                cell.getValue(),
                row.original.statusCheckSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate31 ? moment(row.original.DueDate31).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee31',
          },
        ],
      },
      {
        header: 'Changeover',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate32',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate32,
                row.original.changeoverSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate32
                  ? moment(row.original.StartDate32).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate32',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate32,
                cell.getValue(),
                row.original.changeoverSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate32 ? moment(row.original.DueDate32).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee32',
          },
        ],
      },
      {
        header: 'Obsoletes Approval MRP',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate33',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate33,
                row.original.obsoletesApprovalMrpSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate33
                  ? moment(row.original.StartDate33).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate33',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate33,
                cell.getValue(),
                row.original.obsoletesApprovalMrpSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate33 ? moment(row.original.DueDate33).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee33',
          },
        ],
      },
      {
        header: 'Status Change to AA',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate35',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate35,
                row.original.statusChangeAASla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate35
                  ? moment(row.original.StartDate35).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate35',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate35,
                cell.getValue(),
                row.original.statusChangeAASla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate35 ? moment(row.original.DueDate35).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee35',
          },
        ],
      },
      {
        header: 'Status Change to ZZ',
        columns: [
          {
            header: 'Start Date',
            accessorKey: 'StartDate36',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                cell.getValue(),
                row.original.DueDate36,
                row.original.statusChangeZZSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.StartDate36
                  ? moment(row.original.StartDate36).format(dateFormat)
                  : 'Not Started'}
              </Text>
            ),
          },
          {
            header: 'Due Date',
            accessorKey: 'DueDate36',
            mantineTableBodyCellProps: ({ cell, row }) => ({
              className: getClassName(
                row.original.StartDate36,
                cell.getValue(),
                row.original.statusChangeZZSla,
              ),
            }),
            Cell: ({ row }) => (
              <Text>
                {row.original.DueDate36 ? moment(row.original.DueDate36).format(dateFormat) : '/'}
              </Text>
            ),
          },
          {
            header: 'Assignee',
            accessorKey: 'Assignee36',
          },
        ],
      },
    ],
    [],
  );

  return (
    <RfsLayout>
      <ContentPaper page='bottleneck-report'>
        <Row>
          <Col
            md={{ span: 6 }}
            style={{
              marginTop: '1rem',
              display: 'inline-flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              size='sm'
              color='dark'
              width='2rem'
              style={{ paddingLeft: '20', paddingRight: '20' }}
              onClick={() => {
                navigate('/reports');
              }}
            >
              Back{' '}
            </Button>
            <h1
              className='sectionHeadings'
              style={{ paddingLeft: '10px', fontSize: '28px', color: 'black' }}
            >
              {' '}
              Bottleneck Report{' '}
            </h1>
          </Col>
        </Row>
        <QueryClientProvider client={queryClient}>
          <BottleneckReport columns={columns} unique={'data_id'} />
        </QueryClientProvider>
      </ContentPaper>
    </RfsLayout>
  );
};

export default BottleneckReportPage;
