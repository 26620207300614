import React, { useMemo, useState, useEffect, useContext } from 'react';
import { useTable } from 'react-table';
import {
  Checkbox,
  Select,
  Button,
  TextInput,
  MultiSelect,
  Group,
  LoadingOverlay,
} from '@mantine/core';
import DOMPurify from 'dompurify';

import { AppLoader } from '../../../common/loader';
import { Styles } from './styles';
import moment from 'moment';
import {
  materialTechnicalPackChangeOptions,
  materialArtworkChangeOptions,
} from '../../../../constants';
import { RfsContext } from '../../../../contexts/RfsContext';
import { ProductionBomSchema } from './validation-schema';
import { Comment } from '../../../../pages/supplierChange/scope/helper';

export function BomTable({
  bomModal,
  scopeSelectedSkus,
  form,
  setForm,
  isSubmissionReview,
  errors,
  bomMaterials,
  bomMaterialsLoading,
  setBomModal,
  setBomErrors,
}) {
  const { fetchBomMaterials } = useContext(RfsContext);
  const { editable, scope_id, sku_id } = bomModal;
  const id = scopeSelectedSkus[`${scope_id}_${sku_id}`] ? `${scope_id}_${sku_id}` : sku_id;
  const [boms, setBoms] = useState([]);
  useEffect(() => {
    if (scopeSelectedSkus[id]?.boms && scopeSelectedSkus[id].boms.length > 0)
      setBoms(JSON.parse(JSON.stringify(scopeSelectedSkus[id]?.boms)));
  }, [scopeSelectedSkus[id]?.boms]);

  const isPackChangeDisabled = (data) => {
    return data.material_list && data.material_list.toLowerCase() !== 'new material';
  };

  const handleBomChange = (newValue, identifier, fieldName) => {
    const { index, material_type } = identifier;
    const currentValues = [...boms];
    currentValues[index][fieldName] = newValue;

    if (fieldName === 'active' && !newValue) {
      currentValues[index]['material_list'] = null;
      currentValues[index]['technical_pack_change'] = [];
      currentValues[index]['artwork_change'] = null;
      currentValues[index]['comment'] = null;
    }

    if (fieldName === 'material_list' && newValue.toLowerCase() !== 'new material') {
      currentValues[index]['technical_pack_change'] = [];
      currentValues[index]['artwork_change'] = null;
    }

    if (
      fieldName === 'technical_pack_change' &&
      newValue.includes(materialTechnicalPackChangeOptions.removal)
    ) {
      currentValues[index]['material_list'] = null;
    }
    setBoms(currentValues);

    if (material_type && !bomMaterials[material_type]) {
      fetchBomMaterials([material_type]);
    }
  };

  const isDisabled = (data) => {
    return (
      (data.technical_pack_change &&
        data.technical_pack_change.includes(materialTechnicalPackChangeOptions.removal)) ||
      (data.material_list && data.material_list.toLowerCase() !== 'new material')
    );
  };

  const getMaterials = (type) => {
    let materials = bomMaterials[type] ? [...bomMaterials[type]] : [];
    if (type === 'Z011' || type === 'Z012' || type === 'Z014') {
      materials.unshift({ label: '+ New Material', value: 'New Material' });
    }
    return materials;
  };

  const handleSubmit = () => {
    const currentValues = { ...form };
    currentValues.scopeSelectedSkus[sku_id].boms = [...boms];
    setForm(currentValues);

    const errors = validateBom(currentValues);
    setBomErrors(errors);
    setBomModal({
      open: false,
      scope_id: null,
      sku_id: null,
      editable: false,
      selection_number: null,
    });
  };

  const validateBom = (currentValues) => {
    const productionSiteErrors = {};

    let scopeSelectedSkus = Object.values({
      ...currentValues.scopeSelectedSkus,
    });
    scopeSelectedSkus = scopeSelectedSkus.filter((d) => d.id === id);

    form.selectedScope.skus.forEach((scope) => {
      scopeSelectedSkus.forEach((item) => {
        if (item.scope_id.toString() === scope.id.toString() && item.active) {
          let boms = [];

          const activeBoms = item.boms.filter((b) => b.active);
          if (activeBoms.length === 0) {
            productionSiteErrors[scope.id] = true;
            productionSiteErrors[item.id] = {};
            productionSiteErrors[item.id]['bomErrors'] = true;
          }

          if (item.boms) {
            // boms = item.boms.filter((b) => b.active);
            boms = [...item.boms];
            boms = boms.map((item) => {
              if (typeof item.technical_pack_change === 'string') {
                item.technical_pack_change = item.technical_pack_change.split(',');
              }
              return item;
            });
          }

          if (item.bom_action === 'Modify BOM') {
            boms.forEach((bom, index) => {
              ProductionBomSchema.validate(
                { ...bom, selected_material_number: bom.material_list },
                { abortEarly: false },
              )
                .then(() => {})
                .catch((bomError) => {
                  const bomErrors = bomError.errors;
                  bomErrors.forEach((error) => {
                    productionSiteErrors[item.id] = productionSiteErrors[item.id]
                      ? productionSiteErrors[item.id]
                      : {};
                    productionSiteErrors[item.id]['boms'] = productionSiteErrors[item.id]['boms']
                      ? productionSiteErrors[item.id]['boms']
                      : [];

                    productionSiteErrors[item.id]['boms'][index] = productionSiteErrors[item.id][
                      'boms'
                    ][index]
                      ? {
                          ...productionSiteErrors[item.id]['boms'][index],
                          [error]: true,
                        }
                      : { [error]: true };
                  });
                  productionSiteErrors[item.id]['bomErrors'] = true;
                });
            });
          }
        }
      });
    });

    return {
      errors: {},
      liquidErrors: {},
      materialErrors: {},
      productionSiteErrors,
    };
  };

  const columns = [
    editable && {
      Header: 'Select',
      id: 'checkbox',
      Cell: ({ row }) => {
        return (
          <Checkbox
            checked={row.original.active}
            disabled={isSubmissionReview}
            size='xs'
            onChange={(event) =>
              handleBomChange(
                event.target.checked,
                {
                  index: row.index,
                  material_type: row.original.material_type,
                },
                'active',
              )
            }
          />
        );
      },
    },
    {
      Header: 'BOM Component',
      accessor: 'bom_component',
    },
    {
      Header: 'Component Description',
      accessor: 'component_description',
    },
    {
      Header: 'Component Quantity',
      accessor: 'component_qty',
    },

    {
      Header: 'Unit',
      accessor: 'unit',
    },
    {
      Header: 'Valid From',
      accessor: 'valid_from',
      Cell: ({ row }) => moment(row.original.valid_from, 'YYYYMMDD').format('YYYY-MM-DD'),
    },
    {
      Header: 'Valid till',
      accessor: 'valid_to_date',
      Cell: ({ row }) => moment(row.original.valid_to_date, 'YYYYMMDD').format('YYYY-MM-DD'),
    },
    {
      Header: 'Change Number From',
      accessor: 'change_nr',
    },
    {
      Header: 'Change Number To',
      accessor: 'change_number_to',
    },
    editable && {
      Header: 'Material List',
      Cell: ({ row }) => {
        return (
          <Select
            disabled={
              (row.original.technical_pack_change &&
                row.original.technical_pack_change.includes(
                  materialTechnicalPackChangeOptions.removal,
                )) ||
              isSubmissionReview ||
              !row.original.active
            }
            searchable
            onChange={(event) => handleBomChange(event, { index: row.index }, 'material_list')}
            style={{ width: '450px' }}
            data={getMaterials(row.original.material_type)}
            placeholder='Select'
            value={row.original.material_list}
            error={
              row.original.active &&
              errors[id] &&
              errors[id]['boms'] &&
              errors[id]['boms'][row.index]
                ? errors[id]['boms'][row.index]['material_list']
                : false
            }
          />
        );
      },
    },
    editable && {
      Header: 'Tech Pack Change',
      Cell: ({ row }) => {
        return (
          <MultiSelect
            disabled={
              isPackChangeDisabled(row.original) || isSubmissionReview || !row.original.active
            }
            error={
              row.original.active &&
              errors[id] &&
              errors[id]['boms'] &&
              errors[id]['boms'][row.index]
                ? errors[id]['boms'][row.index]['technical_pack_change']
                : false
            }
            data={[
              {
                value: materialTechnicalPackChangeOptions.location,
                label: materialTechnicalPackChangeOptions.location,
                disabled: isDisabled(row.original),
              },
              {
                value: materialTechnicalPackChangeOptions.drawing,
                label: materialTechnicalPackChangeOptions.drawing,
                disabled: isDisabled(row.original),
              },
              {
                value: materialTechnicalPackChangeOptions.removal,
                label: materialTechnicalPackChangeOptions.removal,
              },
            ]}
            onChange={(event) =>
              handleBomChange(event, { index: row.index }, 'technical_pack_change')
            }
            placeholder='Select'
            searchable
            nothingFound='Nothing found'
            value={
              typeof row.original.technical_pack_change === 'object'
                ? row.original.technical_pack_change
                : row.original.technical_pack_change
                ? row.original.technical_pack_change.split(',')
                : []
            }
          />
        );
      },
    },
    editable && {
      Header: 'Artwork Management',
      Cell: ({ row }) => {
        return (
          <Select
            error={
              row.original.active &&
              errors[id] &&
              errors[id]['boms'] &&
              errors[id]['boms'][row.index]
                ? errors[id]['boms'][row.index]['artwork_change']
                : false
            }
            disabled={isDisabled(row.original) || isSubmissionReview || !row.original.active}
            placeholder='Select'
            searchable
            clearable={!isSubmissionReview}
            style={{ width: '250px' }}
            onChange={(event) => handleBomChange(event, { index: row.index }, 'artwork_change')}
            value={row.original.artwork_change}
            data={[
              {
                value: materialArtworkChangeOptions.text,
                label: materialArtworkChangeOptions.text,
              },
              {
                value: materialArtworkChangeOptions.newColours,
                label: materialArtworkChangeOptions.newColours,
              },
              {
                value: materialArtworkChangeOptions.existingColours,
                label: materialArtworkChangeOptions.existingColours,
              },
              {
                value: materialArtworkChangeOptions.others,
                label: materialArtworkChangeOptions.others,
              },
            ]}
          />
        );
      },
    },
    {
      Header: 'Tech Spec Matrix',
      Cell: ({ row }) => {
        return row.original.tech_spec_matrix ? row.original.tech_spec_matrix : 'No data available';
      },
    },
    editable && {
      Header: 'Comment Box',
      Cell: ({ row }) => {
        return (
          <Comment
            style={{ width: '450px' }}
            disabled={isSubmissionReview || !row.original.active}
            onChange={(value) => handleBomChange(value, { index: row.index }, 'comment')}
            placeholder='Detailed Description of the changes'
            error={
              row.original.active &&
              errors[id] &&
              errors[id]['boms'] &&
              errors[id]['boms'][row.index]
                ? errors[id]['boms'][row.index]['comment']
                : false
            }
            value={row.original.comment}
          />
        );
      },
    },
  ];

  const bom_columns = useMemo(
    () => columns.filter((c) => c),
    [boms.length, Object.keys(bomMaterials).length, errors],
  );

  const tableInstance = useTable({
    columns: bom_columns,
    data: boms || [],
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay loader={<AppLoader size='lg' />} visible={bomMaterialsLoading} />
        <Styles>
          <div style={{ overflowX: 'auto', maxHeight: 'calc(100vh - 250px)' }}>
            <table style={{ width: 'fitContent' }} {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => {
                  const sanitizedProps = DOMPurify.sanitize({
                    ...headerGroup.getHeaderGroupProps(),
                  });
                  return (
                    <tr {...sanitizedProps} id='header-row'>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                      ))}
                    </tr>
                  );
                })}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Styles>
      </div>

      {editable && !isSubmissionReview && (
        <Group
          position='right'
          style={{
            position: 'sticky',
            bottom: '0px',
            background: '#FFFFFF',
            paddingTop: '12px',
          }}
        >
          <Button
            style={{ background: 'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)', color: 'black' }}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </Group>
      )}
    </>
  );
}
