import * as yup from 'yup';
import { materialchangeOverOptions } from '../../../constants';

const supplierFormSchema = yup.object().shape({
  artwork_required: yup.string().nullable().required('artwork_required'),

  supplier_change_type: yup.string().nullable().required('supplier_change_type'),
  changeover_type: yup.string().nullable().required('changeover_type'),
  changeover_date: yup
    .string()
    .nullable()
    .when(['changeover_type'], ([changeover_type]) => {
      if (
        [
          materialchangeOverOptions.hardChange,
          materialchangeOverOptions.notLaterThan,
          materialchangeOverOptions.notSoonerThan,
        ].includes(changeover_type)
      )
        return yup.string().nullable().required('changeover_date');
    }),

  to_be_supplier: yup.array().nullable().min(1, 'to_be_supplier'),

  mix_changeover: yup
    .string()
    .nullable()
    .when(['changeover_type'], ([changeover_type]) => {
      if ([materialchangeOverOptions.mixChange].includes(changeover_type))
        return yup.string().nullable().required('mix_changeover');
    }),
});

export default supplierFormSchema;
