import styled from '@emotion/styled';
import { CrStatusBanner } from '../projectOverview';
import { routes } from '../../../../routes';
import { Link } from 'react-router-dom';

const CustomLink = styled(Link)`
  color: black;
`;

export const getPlantsObject = (plants) => {
  let plantsObject = {};
  plants.forEach((plant) => {
    if (!plantsObject[plant.id])
      plantsObject[plant.producing_plant_id] = {
        pp_id: plant.producing_plant_id,
        step_id: plant.id,
        status: plant.status,
      };
  });

  return plantsObject;
};

export const getPlantName = (id, plantData) => {
  const plant = plantData.find((plant) => plant.id === id);
  return plant.producing_plant;
};

export const StyledTotal = styled.div`
  color: #4c86dd;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
`;

const getVariance = (main = 0, ref = 0) => {
  const variance = parseFloat(main) - parseFloat(ref);
  return (variance < 0 ? -variance : variance).toFixed(3);
};

const getFinalValue = (main, ref) => {
  const parseMain = main ?? 'N/A';
  const parsedRef = ref ?? 'N/A';

  const mainCost = parseMain !== 'N/A' ? parseFloat(main).toFixed(3) : 'N/A';
  const refCost = parsedRef !== 'N/A' ? parseFloat(ref).toFixed(3) : 'N/A';
  const variance = mainCost !== 'N/A' && refCost !== 'N/A' ? getVariance(main, ref) : 'N/A';

  return [`Main: ${mainCost}`, `Ref: ${refCost}`, `Var: ${variance}`];
};

const getValueData = (item, reference, fields, type = 'cc') => {
  const result = {};
  if (type === 'bom') {
    fields.forEach((field) => {
      result[`${field}_final`] = getFinalValue(
        item?.[field],
        reference?.[field === 'price' ? 'Price' : field],
      );
      result[`${field}_excel`] = getFinalValue(
        item?.[field],
        reference?.[field === 'price' ? 'Price' : field],
      ).join(',');
    });
  } else {
    fields.forEach((field) => {
      result[`${field}_final`] = getFinalValue(item?.[field], reference?.[field]);
      result[`${field}_excel`] = getFinalValue(item?.[field], reference?.[field]).join(',');
    });
  }

  return result;
};

export const transformCCData = (data, plantInfo) => {
  return data.map((item) => {
    const reference = item.ref_v[0] || {};
    const fields = ['overall_cost', 'fixed_cost', 'variable_cost'];

    return {
      id: item.id,
      cost_component: item.cost_component,
      cc_description: item.cc_description,
      currency: item.currency,
      comment: item.comment,
      vic_comment: item.vic_comment,
      ...getValueData(item, reference, fields),
      ...plantInfo[item.pp_id],
    };
  });
};

export const transformBomData = (data, plantInfo) => {
  return data.map((item) => {
    const reference = item.reference_bom?.[0] || {};
    const fields = ['total_value', 'quantity', 'price', 'un', 'price_unit', 'scrap'];

    const resource = item.RequestedSkuCostEstimation?.resource || '';

    return {
      component_id: item.component_id,
      component_description: item.component_description,
      component: resource.length > 0 ? resource : item.component,
      currency: item.currency,
      component_unit: item.component_unit,
      un: item.RequestedSkuCostEstimation?.un,
      comment: item.comment,
      vic_comment: item.vic_comment,
      ...getValueData(
        item?.RequestedSkuCostEstimation,
        reference?.PrimaryRefSkuCostEstimation || {},
        fields,
        'bom',
      ),
      ...plantInfo[item.pp_id],
    };
  });
};

export const CostValue = ({ cost = '' }) => {
  const isVariance = cost.includes('Var');
  const costValue = cost.split(':')[1];
  return <div className={isVariance && costValue > 0 ? 'red' : ''}>{costValue}</div>;
};

export const CostValueTemp = ({ cost = '' }) => {
  const isVariance = cost.includes('Var');
  const costValue = cost.split(':')[1];
  return <div className={isVariance && costValue > 0 ? 'red' : ''}>{cost}</div>;
};

export const getCommentUrl = (crNumber, type, page) => {
  const vicApprovalCc = `costing-request/step/vic-approval/cc/comment/${crNumber}`;
  const vicApprovalBom = `costing-request/step/vic-approval/bom/comment/${crNumber}`;
  const vicSubmission = `costing-request/step/vic-submission/${crNumber}`;

  return page === 'vicSubmission' ? vicSubmission : type === 'cc' ? vicApprovalCc : vicApprovalBom;
};

export const getData = (data, readOnlyData = []) => {
  if (readOnlyData.length === 0) return data;

  const finalData = [...data];

  readOnlyData.forEach((sku) => {
    finalData.unshift({
      RequestedSkuCostEstimation: {
        ...sku,
      },
      component_id: sku.id,
      component_description: sku.costing_struc,
      component: sku.resource,
      currency: sku.currency,
      component_unit: sku.unit_of_measure,
      pp_id: sku.requested_producing_plant_id,
      read_only_bom: true,
    });
  });

  return finalData;
};

export const ChildCRBanner = ({ crNumber }) => (
  <CrStatusBanner>
    {
      <CustomLink to={`${routes.costingRequests(crNumber).overview}`}>
        Click here to view the child {crNumber}
      </CustomLink>
    }
  </CrStatusBanner>
);
