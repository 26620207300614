import React, { useContext, useEffect, useState } from 'react';
import { Group, Avatar, Text, Burger, Stack, Button } from '@mantine/core';
import { Navbar, Container, Nav, Dropdown } from 'react-bootstrap';
import { routes } from '../../routes';
import { Link, useNavigate } from 'react-router-dom';
import './NavbarComp.css';
import { AuthContext } from '../../contexts/AuthContext';
import { crRoles, roles } from '../../constants';
import useActiveModule from '../../hooks/useActiveModule';
import { UserRole } from './Role';

export default function NavbarComp({ opened, setOpened }) {
  const {
    userEmail,
    userName,
    userRole,
    profilePhoto,
    user = {},
    crUserRoles,
  } = useContext(AuthContext);
  const activeModule = useActiveModule();
  const navigate = useNavigate();
  const [links, setLinks] = useState({});

  useEffect(() => {
    if (user) {
      const { rfs_registered = null } = user || {};
      setLinks({
        rfs: !rfs_registered ? routes.home.register : `/`,
        costingRequests: routes.costingRequests().root,
      });
    }
  }, [user]);

  const getName = () => {
    if (userName?.includes(' ')) {
      if (userName !== null) {
        const fullName = userName.replace('(GLOBAL-V)', '');
        return fullName;
      }
    } else {
      if (userName !== null) {
        const fullName = userName
          .replace('(GLOBAL-V)', '')
          .split(/(?=[A-Z])/)
          .join(' ');
        return fullName;
      }
    }
  };
  const getEmail = () => {
    const Email = userEmail;
    return Email;
  };
  const getInitials = () => {
    if (userName?.includes(' ')) {
      const fullName = userName?.split(' ');
      const initials = fullName?.shift()?.charAt(0) + fullName?.pop()?.charAt(0);
      return initials;
    } else {
      const initials = userName?.charAt(0);
      return initials;
    }
  };

  const handleAvatarClick = () => {
    if (activeModule === 'rfs') return;
    navigate(routes.costingRequests().profile.root);
  };

  return (
    <Navbar style={{ height: '73px', backgroundColor: 'rgba(0,0,0,.9)' }}>
      <Container style={{ padding: '0.5rem' }} fluid>
        {/* <MediaQuery largerThan='md' styles={{ display: 'none' }}> */}
        <Burger
          opened={opened}
          onClick={() => setOpened((o) => !o)}
          size='md'
          mr='xl'
          color='white'
        />
        {/* </MediaQuery> */}
        <Navbar.Brand as={Link} to={activeModule === 'rfs' ? '/' : routes.costingRequests().root}>
          <img
            style={{ paddingLeft: '10px' }}
            src='/images/FullColor-White.png'
            height='25px'
            className='d-inline-block align-top'
            alt='STM logo'
          />
        </Navbar.Brand>
        <Navbar.Brand
          as={Link}
          to={activeModule === 'rfs' ? '/' : routes.costingRequests().root}
          className='photo1'
          style={{ borderLeft: '1px solid gray' }}
        >
          <img
            style={{ paddingLeft: '10px' }}
            src='/images/STM_Logo_White_Yellow.svg'
            width='100px'
            className='d-inline-block align-top'
            alt='STM logo'
          />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className='justify-content-end'>
          <Nav className='me-auto '>
            {[roles.superAdmin].includes(userRole) || crUserRoles.includes(crRoles.userAdmin) ? (
              <Nav.Link
                as={Link}
                className='navlink'
                to={
                  activeModule === 'rfs' ? routes.admin.root : routes.costingRequests().admin.root
                }
              >
                <Button style={{ marginRight: '1rem' }} variant='gradient'>
                  Admin
                </Button>
              </Nav.Link>
            ) : (
              <div></div>
            )}
          </Nav>

          <Dropdown className='projects-dropdown'>
            <Dropdown.Toggle
              variant='filled'
              id='dropdown-basic'
              style={{ backgroundColor: '#2D2D2D', color: 'white' }}
            >
              {activeModule === 'rfs' ? 'RFS' : 'Costing Requests'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={links.rfs} active={activeModule === 'rfs'}>
                RFS
              </Dropdown.Item>
              <Dropdown.Item as={Link} to={links.costingRequests} active={activeModule !== 'rfs'}>
                Costing Requests
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button
            style={{
              marginRight: '1rem',
            }}
            variant='gradient'
            onClick={() =>
              navigate(
                activeModule === 'rfs'
                  ? routes.RaiseTicket().root
                  : routes.costingRequests().raiseticket.root,
              )
            }
          >
            {'Technical Support'}
          </Button>
          <Nav>
            <Group>
              <Avatar
                size={50}
                className='avatar'
                color='primary'
                style={{
                  objectFit: 'fill',
                  backgroundColor: 'rgba(255, 249, 219, 1)',
                  cursor: activeModule === 'rfs' ? '' : 'pointer',
                }}
                src={profilePhoto && URL.createObjectURL(profilePhoto)}
                alt=''
                onClick={handleAvatarClick}
              >
                {!profilePhoto && getInitials()}
              </Avatar>
              <Stack className='data' spacing={0}>
                <Text
                  color='primary'
                  style={{
                    color: '#e5b611',
                  }}
                  className='data'
                >
                  <b>{getName()}</b>
                </Text>
                <Text size='xs' color='dimmed' className='data'>
                  {getEmail()}
                </Text>

                <UserRole
                  activeModule={activeModule}
                  userRole={userRole}
                  crUserRoles={crUserRoles}
                />
              </Stack>
            </Group>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
