import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, NumberInput, Text, Select, Checkbox } from '@mantine/core';
import moment from 'moment';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import MantineTable from '../../../../common/table/mantine';
import { implementationColumns } from '../../../../common/table/template/implementation';
import { CommonColumnsContext } from '../../../../contexts/CommonColumns';
import { AppLoader } from '../../../../components/common/loader';
import { AuthContext } from '../../../../contexts/AuthContext';
import { DownloadExcel } from '../../../../common/excel';
import { AttachmentsModal } from '../../../../common/attachments';
import { attachmentChunks } from '../../../../utils/helpers';
import { SupplierList } from '../../scope/helper';

const MAX_ATTACHMENTS = 5;

export const ArtworkDevelopment = ({ permissions, userRole }) => {
  const { rfsNumber } = useParams();
  const { setLogsModal } = useContext(CommonColumnsContext);
  const { users } = useContext(AuthContext);
  const [materials, setMaterials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeMaterials, setActiveMaterials] = useState({});
  const [form, setForm] = useState({});
  const [attachmentModal, setAttachmentModal] = useState({
    open: false,
    artwork_dev: null,
    status_id: null,
    attachments: [],
  });
  const readOnly = !permissions.includes(userRole);

  const fetch = useCallback(
    (loading = true) => {
      setLoading(loading);
      axios
        .get(`/supplier-changes/artwork_development/${rfsNumber}`)
        .then(
          (res) => {
            setMaterials(res.data.data);
          },
          (err) => {
            console.log(err);
          },
        )
        .finally(() => setLoading(false));
    },
    [rfsNumber],
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleRowSelection = (data) => {
    let currentValues = { ...activeMaterials };
    currentValues = { ...data };

    setActiveMaterials(currentValues);
  };

  const handleChange = (value, name, id) => {
    const currentValues = { ...form };
    if (!currentValues[id]) currentValues[id] = {};
    currentValues[id] = {
      ...currentValues[id],
      [name]: value?.target?.type === 'checkbox' ? value.target.checked : value,
    };

    setForm(currentValues);
  };

  const handleSubmit = ({ id, int_rfs_impl_artwork_dev }, type) => {
    setForm((state) => {
      return {
        ...state,
        [int_rfs_impl_artwork_dev.id]: {
          ...state[int_rfs_impl_artwork_dev.id],
          [`${type}_loading`]: true,
        },
      };
    });

    const payload = {
      [type]: form[int_rfs_impl_artwork_dev.id][type],
      material_uid: int_rfs_impl_artwork_dev.material_uid,
      supplier_id: int_rfs_impl_artwork_dev.supplier_id,
      status_id: id,
    };

    axios
      .post(`supplier-changes/artwork_development/${rfsNumber}`, payload)
      .then(
        (res) => {
          fetch(false);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() =>
        setForm((state) => {
          return {
            ...state,
            [int_rfs_impl_artwork_dev.id]: {},
          };
        }),
      );
  };

  const handleFileSelect = (files) => {
    const currentValues = { ...attachmentModal };
    const attachments = currentValues.attachments || [];
    const filesLength = attachments.length + files.length;

    if (filesLength <= MAX_ATTACHMENTS) {
      files.forEach((file) => attachments.push(file));
      currentValues.attachments = attachments;
      setAttachmentModal(currentValues);
    }
  };

  const handleFileRemove = (index) => {
    const currentValues = { ...attachmentModal };
    currentValues.attachments = currentValues.attachments.filter((i, k) => k !== index);

    setAttachmentModal(currentValues);
  };

  const handleAttachmentSubmit = async () => {
    const { artwork_dev, attachments } = attachmentModal;
    const oldAttachments =
      (artwork_dev.gfp_attachment || '').length > 0 ? artwork_dev.gfp_attachment.split(',') : [];

    const attachmentNames = new Set(attachments.map((file) => file.name));
    const deletedAttachments = oldAttachments.filter((name) => !attachmentNames.has(name));

    const formData = new FormData();
    formData.append('rfs_number', rfsNumber);
    formData.append('id', attachmentModal.artwork_dev.id);

    for (const item of deletedAttachments) {
      formData.append('deletedAttachments[]', item);
    }

    const chunks = attachmentChunks(attachments.filter((file) => file.size));
    if (Object.keys(chunks).length > 0) {
      for (const chunk of Object.values(chunks)) {
        for (const file of chunk) {
          formData.append('attachments', file);
        }
        await uploadAttachment(formData);
      }
    } else {
      await uploadAttachment(formData);
    }

    setAttachmentModal({ ...attachmentModal, open: false });
    fetch(false);
  };

  const uploadAttachment = async (formData) => {
    try {
      const res = await axios.post(`/rfs/artwork/attachment`, formData);
      return res;
    } catch (error) {
      console.error('Error while handling attachment submission:', error);
    }
  };

  const handleRemoveAttachment = (payload) => {
    axios
      .post(`supplier-changes/artwork_development/${rfsNumber}`, payload)
      .then((res) => fetch(false));
  };

  const SaveButton = ({ clickHandler, loading }) => (
    <Button
      style={{ padding: '10px', marginTop: '10px' }}
      onClick={clickHandler}
      position='Right'
      size='sm'
      color='primary'
      variant='gradient'
      gradient={{ from: '#e3af32', to: '#f4e00f' }}
      radius='xs'
      disabled={loading}
      fullWidth
    >
      {loading ? <AppLoader /> : 'Submit'}
    </Button>
  );

  let columns = useMemo(
    () => [
      {
        accessorKey: 'int_rfs_sc_material.material_code',
        header: 'Old Material Code',
      },
      {
        accessorKey: 'int_rfs_sc_material.material_description',
        header: 'Old Material Description',
      },
      {
        accessorKey: 'int_rfs_impl_material_creation.new_material_code',
        header: 'New Material Code',
        Cell: ({
          row: {
            original: { int_rfs_impl_material_creation },
          },
        }) =>
          int_rfs_impl_material_creation?.new_material_code
            ? int_rfs_impl_material_creation?.new_material_code
            : 'N/A',
      },
      {
        accessorKey: 'int_rfs_impl_material_creation.new_material_desc',
        header: 'New Material Description',
        Cell: ({
          row: {
            original: { int_rfs_impl_material_creation },
          },
        }) =>
          int_rfs_impl_material_creation?.new_material_desc
            ? int_rfs_impl_material_creation?.new_material_desc
            : 'N/A',
      },
      {
        accessorKey: 'int_rfs_sc_material.plant',
        header: 'Plants',
        Cell: ({ row }) => (
          <SupplierList suppliers={(row?.original?.int_rfs_sc_material?.plant || '').split(',')} />
        ),
      },
      {
        accessorKey: 'int_rfs_sc_material.current_suppliers',
        header: 'Current Suppliers',
        Cell: ({ row }) => (
          <div>
            <SupplierList
              suppliers={(row?.original?.int_rfs_sc_material?.current_suppliers || '').split(',')}
            />
          </div>
        ),
      },
      {
        accessorKey: 'int_rfs_sc_supplier.supplier_name',
        header: 'To Be Supplier',
        Cell: ({ row }) => {
          let suppliers = row.original.int_rfs_sc_supplier;
          suppliers = `${suppliers.supplier_number} ${suppliers.supplier_name}`;

          return <div>{suppliers}</div>;
        },
      },
      {
        accessorKey: 'int_rfs_impl_artwork_dev.artwork_po_provider',
        header: 'PO Number Provider for this artwork',
        Cell: ({ row }) => {
          const info = row.original.int_rfs_impl_artwork_dev;
          const isDisabled =
            !form[info.id] ||
            !form[info.id]['artwork_po_provider'] ||
            info.gfp_provided ||
            ['Closed', 'Not Started'].includes(row.original.status);

          return info?.artwork_po_provider ? (
            <Text>{info.artwork_po_provider}</Text>
          ) : (
            <>
              <Select
                placeholder='PO Number Provider'
                searchable
                nothingFound='No options'
                onChange={(event) => handleChange(event, 'artwork_po_provider', info.id)}
                value={form[info.id] ? form[info.id]['artwork_po_provider'] : null}
                disabled={
                  ['Closed', 'Not Started'].includes(row.original.status) ||
                  info.gfp_provided ||
                  readOnly
                }
                style={{ width: '200px' }}
                clearable
                data={users.map((u) => {
                  return {
                    label: u.name,
                    value: u.email,
                  };
                })}
                withinPortal
              />

              {!isDisabled && (
                <SaveButton
                  disabled={isDisabled}
                  loading={form[info.id]?.artwork_po_provider_loading}
                  clickHandler={() => handleSubmit(row.original, 'artwork_po_provider')}
                />
              )}
            </>
          );
        },
      },
      {
        accessorKey: 'int_rfs_impl_artwork_dev.artwork_po_number',
        header: 'PO Number for Artwork',
        Cell: ({ row }) => {
          const info = row.original.int_rfs_impl_artwork_dev;
          const isDisabled =
            !form[info.id] ||
            !form[info.id]['artwork_po_number'] ||
            !info.artwork_po_provider ||
            info.gfp_provided ||
            ['Closed', 'Not Started'].includes(row.original.status);

          return info.artwork_po_number ? (
            <Text>{info.artwork_po_number}</Text>
          ) : (
            <>
              <NumberInput
                placeholder='PO Number'
                hideControls
                radius='md'
                max={9999999999}
                onChange={(event) => handleChange(event, 'artwork_po_number', info.id)}
                value={form[info.id] ? form[info.id]['artwork_po_number'] : ''}
                disabled={
                  ['Closed', 'Not Started'].includes(row.original.status) ||
                  info.gfp_provided ||
                  !info.artwork_po_provider ||
                  readOnly
                }
              />

              {!isDisabled && (
                <SaveButton
                  disabled={isDisabled}
                  loading={form[info.id]?.artwork_po_number_loading}
                  clickHandler={() => handleSubmit(row.original, 'artwork_po_number')}
                />
              )}
            </>
          );
        },
      },
      {
        id: 'int_rfs_impl_artwork_dev.artwork_briefed',
        header: 'Artwork Briefed to Agency',
        Cell: ({ row }) => {
          const info = row.original.int_rfs_impl_artwork_dev;
          const isDisabled =
            !form[info.id] ||
            !form[info.id]['artwork_briefed'] ||
            ['Closed', 'Not Started'].includes(row.original.status);

          return info.artwork_briefed ? (
            <Checkbox label='Completed' disabled={true} defaultChecked={true} />
          ) : (
            <>
              <Checkbox
                checked={form[info.id] ? form[info.id]['artwork_briefed'] : false}
                label='Completed'
                disabled={['Closed', 'Not Started'].includes(row.original.status) || readOnly}
                onChange={(event) => handleChange(event, 'artwork_briefed', info.id)}
              />

              {!isDisabled && (
                <SaveButton
                  disabled={isDisabled}
                  loading={form[info.id]?.artwork_briefed_loading}
                  clickHandler={() => handleSubmit(row.original, 'artwork_briefed')}
                />
              )}
            </>
          );
        },
      },
      {
        id: 'int_rfs_impl_artwork_dev.color_target_setting_finalized',
        header: 'Color Target setting finalized',
        Cell: ({ row }) => {
          const info = row.original.int_rfs_impl_artwork_dev;
          const isDisabled =
            !form[info.id] ||
            !form[info.id]['color_target_setting_finalized'] ||
            ['Closed', 'Not Started'].includes(row.original.status);

          return info.color_target_setting_finalized ? (
            <Checkbox label='Completed' disabled={true} defaultChecked={true} />
          ) : (
            <>
              <Checkbox
                label='Completed'
                checked={form[info.id] ? form[info.id]['color_target_setting_finalized'] : false}
                disabled={
                  info.color_target_setting_finalized ||
                  ['Closed', 'Not Started'].includes(row.original.status) ||
                  readOnly
                }
                onChange={(event) => handleChange(event, 'color_target_setting_finalized', info.id)}
              />

              {!isDisabled && (
                <SaveButton
                  disabled={isDisabled}
                  loading={form[info.id]?.color_target_setting_finalized_loading}
                  clickHandler={() => handleSubmit(row.original, 'color_target_setting_finalized')}
                />
              )}
            </>
          );
        },
      },
      {
        id: 'int_rfs_impl_artwork_dev.artwork_approved',
        header: 'Artwork Approved/Artwork sent to supplier',
        Cell: ({ row }) => {
          const info = row.original.int_rfs_impl_artwork_dev;
          const isDisabled =
            !form[info.id] ||
            !form[info.id]['artwork_approved'] ||
            info.gfp_provided ||
            ['Closed', 'Not Started'].includes(row.original.status);

          return info.artwork_approved ? (
            <Checkbox label='Completed' disabled={true} defaultChecked={true} />
          ) : (
            <>
              <Checkbox
                label='Completed'
                checked={form[info.id] ? form[info.id]['artwork_approved'] : false}
                disabled={
                  info.artwork_approved ||
                  ['Closed', 'Not Started'].includes(row.original.status) ||
                  readOnly
                }
                onChange={(event) => handleChange(event, 'artwork_approved', info.id)}
              />

              {!isDisabled && (
                <SaveButton
                  disabled={isDisabled}
                  loading={form[info.id]?.artwork_approved_loading}
                  clickHandler={() => handleSubmit(row.original, 'artwork_approved')}
                />
              )}
            </>
          );
        },
      },
      {
        accessorKey: 'int_rfs_impl_artwork_dev.supplier_po_provider',
        header: 'PO Number Provider for Supplier',
        Cell: ({ row }) => {
          const info = row.original.int_rfs_impl_artwork_dev;
          const isDisabled =
            !form[info.id] ||
            !form[info.id]['supplier_po_provider'] ||
            info.gfp_provided ||
            ['Closed', 'Not Started'].includes(row.original.status);

          return info.supplier_po_provider ? (
            <Text>{info.supplier_po_provider}</Text>
          ) : (
            <>
              <Select
                placeholder='PO Number Provider'
                searchable
                nothingFound='No options'
                disabled={
                  ['Closed', 'Not Started'].includes(row.original.status) ||
                  info.gfp_provided ||
                  readOnly
                }
                value={form[info.id] ? form[info.id]['supplier_po_provider'] : null}
                onChange={(event) => handleChange(event, 'supplier_po_provider', info.id)}
                data={users.map((u) => {
                  return {
                    label: u.name,
                    value: u.email,
                  };
                })}
                style={{ width: '200px' }}
                withinPortal
              />
              {!isDisabled && (
                <SaveButton
                  disabled={isDisabled}
                  loading={form[info.id]?.supplier_po_provider_loading}
                  clickHandler={() => handleSubmit(row.original, 'supplier_po_provider')}
                />
              )}
            </>
          );
        },
      },
      {
        accessorKey: 'int_rfs_impl_artwork_dev.supplier_po_number',
        header: 'PO Number for Supplier',
        Cell: ({ row }) => {
          const info = row.original.int_rfs_impl_artwork_dev;
          const isDisabled =
            !form[info.id] ||
            !form[info.id]['supplier_po_number'] ||
            !info.supplier_po_provider ||
            info.gfp_provided ||
            ['Closed', 'Not Started'].includes(row.original.status);

          return info.supplier_po_number ? (
            <Text>{info.supplier_po_number}</Text>
          ) : (
            <>
              <NumberInput
                radius='md'
                placeholder='PO Number'
                hideControls
                max={9999999999}
                onChange={(event) => handleChange(event, 'supplier_po_number', info.id)}
                value={form[info.id] ? form[info.id]['supplier_po_number'] : ''}
                disabled={
                  !info.supplier_po_provider ||
                  info.gfp_provided ||
                  ['Closed', 'Not Started'].includes(row.original.status) ||
                  readOnly
                }
              />

              {!isDisabled && (
                <SaveButton
                  disabled={isDisabled}
                  loading={form[info.id]?.supplier_po_number_loading}
                  clickHandler={() => handleSubmit(row.original, 'supplier_po_number')}
                />
              )}
            </>
          );
        },
      },
      {
        id: 'attachment',
        header: 'GFP Attachment',
        Cell: ({ row }) => {
          const info = row.original.int_rfs_impl_artwork_dev;
          const attachments =
            (info?.gfp_attachment || '').length > 0
              ? info.gfp_attachment.split(',').map((name, index) => {
                  return { id: index + 1, name: name };
                })
              : [];
          return (
            <Button
              disabled={['Closed', 'Not Started'].includes(row.original.status) || readOnly}
              variant='gradient'
              gradient={{ from: '#e3af32', to: '#f4e00f' }}
              onClick={() =>
                setAttachmentModal({
                  open: true,
                  artwork_dev: row.original.int_rfs_impl_artwork_dev,
                  status_id: row.original.id,
                  attachments: attachments,
                })
              }
            >
              Attachment
            </Button>
          );
        },
      },
      {
        id: 'int_rfs_impl_artwork_dev.gfp_provided',
        header: 'GFP Approved',
        Cell: ({ row }) => {
          const info = row.original.int_rfs_impl_artwork_dev;
          const isDisabled =
            !form[info.id] ||
            !form[info.id]['gfp_provided'] ||
            ['Closed', 'Not Started'].includes(row.original.status);

          return info.gfp_provided ? (
            <Checkbox label='Completed' disabled={true} defaultChecked={true} />
          ) : (
            <>
              <Checkbox
                label='Completed'
                disabled={
                  info.gfp_provided ||
                  ['Closed', 'Not Started'].includes(row.original.status) ||
                  readOnly
                }
                onChange={(event) => handleChange(event, 'gfp_provided', info.id)}
              />

              {!isDisabled && (
                <SaveButton
                  disabled={isDisabled}
                  loading={form[info.id]?.gfp_provided_loading}
                  clickHandler={() => handleSubmit(row.original, 'gfp_provided')}
                />
              )}
            </>
          );
        },
      },
      {
        accessorKey: 'int_rfs_impl_artwork_dev.gfp_approved',
        header: 'EAN Approved',
        Cell: ({ row }) => {
          const info = row.original.int_rfs_impl_artwork_dev;
          const isDisabled =
            !form[info.id] ||
            !form[info.id]['gfp_approved'] ||
            ['Closed', 'Not Started'].includes(row.original.status);

          return info.gfp_approved ? (
            <Text>{info.gfp_approved}</Text>
          ) : (
            <>
              <Select
                disabled={
                  !info.gfp_provided ||
                  ['Closed', 'Not Started'].includes(row.original.status) ||
                  readOnly
                }
                onChange={(event) => handleChange(event, 'gfp_approved', info.id)}
                placeholder='Approved/Not Approved'
                searchable
                nothingFound='No options'
                data={['Approved', 'Not Approved']}
                withinPortal
              />
              {!isDisabled && (
                <SaveButton
                  disabled={isDisabled}
                  loading={form[info.id]?.gfp_approved_loading}
                  clickHandler={() => handleSubmit(row.original, 'gfp_approved')}
                />
              )}
            </>
          );
        },
      },
      {
        accessorKey: 'int_rfs_impl_artwork_dev.changes_made',
        header: 'Changes Made',
        Cell: ({ row }) => {
          const info = row.original.int_rfs_impl_artwork_dev;
          const isDisabled =
            !form[info.id] ||
            !form[info.id]['changes_made'] ||
            ['Closed', 'Not Started'].includes(row.original.status);
          return info.changes_made ? (
            <Text>{info.changes_made}</Text>
          ) : (
            <>
              <Select
                disabled={
                  !info.gfp_approved ||
                  info.gfp_approved === 'Approved' ||
                  ['Closed', 'Not Started'].includes(row.original.status) ||
                  readOnly
                }
                onChange={(event) => handleChange(event, 'changes_made', info.id)}
                placeholder='Changed in ERP/ Changed the Artwork'
                searchable
                nothingFound='No options'
                data={['Changed in ERP', 'Changed the Artwork']}
                withinPortal
              />
              {!isDisabled && (
                <SaveButton
                  disabled={isDisabled}
                  loading={form[info.id]?.supplier_po_number_loading}
                  clickHandler={() => handleSubmit(row.original, 'changes_made')}
                />
              )}
            </>
          );
        },
      },
    ],
    [form],
  );

  columns = columns.concat(
    implementationColumns(
      setLogsModal,
      null,
      { title: 'Artwork Development', hidden: ['action'] },
      readOnly,
    ),
  );

  return (
    <div>
      <DownloadExcel
        data={materials.map(
          ({
            int_rfs_sc_material,
            int_rfs_sc_supplier,
            int_rfs_impl_artwork_dev,
            int_rfs_impl_material_creation,
            status,
            sla_date,
            finished_date,
          }) => {
            return {
              'Old Material Code': int_rfs_sc_material.material_code,
              'Old Material Description': int_rfs_sc_material.material_description,
              'New Material Code': int_rfs_impl_material_creation?.new_material_code,
              'New Material Description': int_rfs_impl_material_creation?.new_material_desc,
              Plants: int_rfs_sc_material.plant,
              'Current Suppliers': int_rfs_sc_material?.current_suppliers,
              'To Be Supplier': `${int_rfs_sc_supplier?.supplier_number} ${int_rfs_sc_supplier?.supplier_name}`,
              'PO Number Provider for this artwork':
                int_rfs_impl_artwork_dev?.artwork_po_provider || '',
              'PO Number for Artwork': int_rfs_impl_artwork_dev?.artwork_po_number || '',
              'Artwork Briefed to Agency': int_rfs_impl_artwork_dev?.artwork_briefed
                ? 'Completed'
                : '',
              'Color Target setting finalized':
                int_rfs_impl_artwork_dev?.color_target_setting_finalized ? 'Completed' : '',
              'Artwork Approved/Artwork sent to supplier':
                int_rfs_impl_artwork_dev?.artwork_approved ? 'Completed' : '',
              'PO Number Provider for Supplier':
                int_rfs_impl_artwork_dev?.supplier_po_provider || '',
              'PO Number for Supplier': int_rfs_impl_artwork_dev?.supplier_po_number || '',
              'GFP Attachment': int_rfs_impl_artwork_dev?.gfp_attachment || '',
              'GFP Approved': int_rfs_impl_artwork_dev?.gfp_provided ? 'Completed' : '',
              'EAN Approved': int_rfs_impl_artwork_dev?.gfp_approved || '',
              'Changes Made': int_rfs_impl_artwork_dev?.changes_made || '',
              Status: status,
              'Step Deadline': moment(sla_date).format('DD-MM-YYYY'),
              'Step Finished': finished_date
                ? moment(finished_date).format('DD-MM-YYYY')
                : 'Not Finished',
            };
          },
        )}
        filename='Artwork_Development'
      />
      <MantineTable
        columns={columns}
        initialData={materials}
        unique={'id'}
        handleRowSelection={handleRowSelection}
        activeRows={activeMaterials}
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
      />

      {attachmentModal.open && (
        <AttachmentsModal
          modal={attachmentModal}
          setModal={setAttachmentModal}
          onChange={handleFileSelect}
          onSubmit={handleAttachmentSubmit}
          onRemove={handleFileRemove}
          readOnly={attachmentModal.artwork_dev.gfp_approved === 'Approved'}
        />
      )}
    </div>
  );
};
