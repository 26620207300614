import React from 'react';
import { Modal, Button, Container, Grid, Group, Text } from '@mantine/core';
import './ToggleModal.css';
import { CircleCheck } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';

function RegisterAlert({ opened, setOpened }) {
  const navigate = useNavigate();

  const handleClick = () => {
    localStorage.clear();
    setOpened(false);
    navigate('/');
  };

  return (
    <Modal
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      opened={opened}
      onClose={() => setOpened(false)}
      title='Registration successful!'
      className='confirm-modal'
    >
      <Container>
        <Grid>
          <Grid.Col span={12}>
            <Group noWrap className='confirm-modal-group'>
              <CircleCheck color='green' size='5rem' />
              <Text size='sm'>Registration successful. Please wait for approval.</Text>
            </Group>
          </Grid.Col>

          <Grid.Col span={3} offset={9}>
            <Button variant='gradient' onClick={handleClick} fullWidth>
              OK
            </Button>
          </Grid.Col>
        </Grid>
      </Container>
    </Modal>
  );
}

export default RegisterAlert;
