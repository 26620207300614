import { Modal, Button, Grid, Select } from '@mantine/core';
import { CustomTextArea } from '../../../../../common/inputs/CustomTextArea';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { showAlert } from '../../../../../utils/alerts';
import { axios } from '../../../../../utils/axios';
import { CostingRequestsContext } from '../../../../../contexts/CostingRequest';
import { NpsIds } from '../../../../../constants';
import { NpsComponent } from '../../../../../common/nps';
import styled from '@emotion/styled';
import { AppLoader } from '../../../../../components/common/loader';

const Container = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
`;

export const RejectionModal = ({
  activePlant,
  setBtnDisable,
  modal,
  setModal,
  handleRefresh,
  setStepStatus,
}) => {
  const { getStepStatus } = useContext(CostingRequestsContext);
  const { crNumber } = useParams();
  const [npsOpened, setNpsOpened] = useState(false);
  const [form, setForm] = useState({
    comment: null,
    status: 'Rejected',
    plant: activePlant.producing_plant,
    reference_sku_change_needed: null,
  });
  const [loading, setLoading] = useState(false);

  const handleCancel = () => setModal(false);

  const onChange = (name, value) => {
    setForm((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  };

  const isDisabled = () => {
    const { reference_sku_change_needed, comment } = form;
    return !reference_sku_change_needed || !comment || (comment.trim() || '').length === 0;
  };

  const handleReject = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`costing-request/step/vic-approval/${crNumber}`, form);
      if (res?.data?.success) {
        getStepStatus();
        showAlert({ message: 'Request Successful' }, 'success');
        setBtnDisable(true);
        setNpsOpened(true);
      } else {
        showAlert('', 'error');
      }
    } catch {
    } finally {
      handleCancel();
      handleRefresh();
      setStepStatus('Closed');
      setLoading(false);
    }
  };

  return (
    <Modal
      opened={modal}
      onClose={handleCancel}
      title='Reject Request'
      size='lg'
      closeOnClickOutside={false}
      styles={{
        title: { fontWeight: 600 },
        body: { minHeight: '200px' },
      }}
    >
      <Container>
        <Select
          label='Does the reference SKU need to be changed?'
          data={[
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ]}
          value={form.reference_sku_change_needed}
          clearable
          onChange={(value) => onChange('reference_sku_change_needed', value)}
          withAsterisk
        />

        <div>
          <CustomTextArea
            label={'Please explain briefly the reason for rejection (min 10 characters).'}
            placeholder='Enter your comments here'
            value={form.comment}
            onChange={(value) => onChange('comment', value)}
            style={{
              width: '100%',
            }}
            withAsterisk
          />
        </div>

        <div>
          <Grid gutter='md'>
            <Grid.Col span={4} />
            <Grid.Col span={4}>
              <Button color='gray' radius='md' fullWidth onClick={handleCancel}>
                Cancel
              </Button>
            </Grid.Col>
            <Grid.Col span={4}>
              <Button
                color='red'
                radius='md'
                fullWidth
                onClick={handleReject}
                disabled={isDisabled()}
              >
                {loading ? <AppLoader size='sm' /> : 'Reject'}
              </Button>
            </Grid.Col>
          </Grid>
        </div>
      </Container>

      {npsOpened && (
        <NpsComponent
          crNumber={crNumber}
          opened={npsOpened}
          setOpened={setNpsOpened}
          callback={() => {
            setNpsOpened(false);
          }}
          id={NpsIds().vic}
        />
      )}
    </Modal>
  );
};
