import React from 'react';
import { Button, Grid, Menu } from '@mantine/core';
import csvDownload from 'json-to-csv-export';
import moment from 'moment';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { convertDateFormat, getLocale } from '../../utils/helpers';

export const DownloadExcel = ({ data, filename = 'Data', fetch = null }) => {
  const downloadCsv = async (rows = []) => {
    const dataToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };

    const headers = Object.keys(rows[0] || []);
    const locale = getLocale();

    // Normalize locale to handle 'en-us' as 'en' and other variations
    const normalizedLocale = locale.replace('_', '-').toLowerCase(); // 'en-us' -> 'en-us', 'en_US' -> 'en-us'

    try {
      await import(`moment/locale/${normalizedLocale}`).catch((err) => {
        moment.locale('en'); // Fallback to 'en' if the locale is not found
      });
      moment.locale(normalizedLocale);

      dataToExport.data = convertDateFormat(rows, 'excel', 'L', moment);
      dataToExport.filename = `Download_${filename}_` + moment().format('YYYY-MM-DD HH:mm:ss');
      dataToExport.headers = headers;
      csvDownload(dataToExport);
    } catch (error) {
      moment.locale('en'); // Fallback to a default locale if needed
      dataToExport.data = convertDateFormat(rows, 'excel', 'L', moment);
      dataToExport.filename = `Download_${filename}_` + moment().format('YYYY-MM-DD HH:mm:ss');
      dataToExport.headers = headers;
      csvDownload(dataToExport);
    }
  };

  const downloadXlsx = async (rows) => {
    const locale = getLocale();

    // Normalize the locale (e.g., 'en-us' becomes 'en-us', 'en_US' becomes 'en-us')
    const normalizedLocale = locale.replace('_', '-').toLowerCase();

    try {
      // Try importing the locale
      await import(`moment/locale/${normalizedLocale}`).catch((err) => {
        moment.locale('en'); // Fallback to default if locale is not available
      });

      moment.locale(normalizedLocale);

      // Convert date formats in the rows
      rows = convertDateFormat(rows, 'excel', 'L', moment);

      // Create the XLSX workbook
      const worksheet = XLSX.utils.json_to_sheet(rows);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      // Write the workbook to a buffer and create a Blob for download
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

      // Trigger file download
      saveAs(blob, `${filename}_${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`);
    } catch (error) {
      // In case of failure, fall back to default locale and proceed with download
      moment.locale('en'); // Fallback to a default locale if needed
      rows = convertDateFormat(rows, 'excel', 'L', moment);

      const worksheet = XLSX.utils.json_to_sheet(rows);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(blob, `${filename}_${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`);
    }
  };

  return (
    <Grid justify='flex-end'>
      <Grid.Col
        span={3}
        style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}
      >
        <Menu shadow='md' width={150}>
          <Menu.Target>
            <Button
              size='sm'
              variant='gradient'
              radius='md'
              color='green'
              // onClick={() => {
              //   const items = fetch ? fetch() : data;
              //   downloadXlsx(items);
              // }}
            >
              Export
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              component='button'
              onClick={() => {
                const items = fetch ? fetch() : data;
                downloadCsv(items);
              }}
            >
              CSV
            </Menu.Item>

            <Menu.Item
              component='button'
              onClick={() => {
                const items = fetch ? fetch() : data;
                downloadXlsx(items);
              }}
            >
              XLSX
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Grid.Col>
    </Grid>
  );
};
