import { useState } from 'react';
import {
  Group,
  Text,
  Space,
  TextInput,
} from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  max-height: 260px;
  min-height: 250px;
  overflow-y: auto;
  margin-top: 16px;
`;

const RoleName = styled(Text)`
  font-weight: 500;
`;

const RoleDetails = styled(Text)`
  font-size: 12px;
  color: dimmed;
  font-weight: 400;
`;


const renderLabelValue = (label, value) => {
  if (!value) return null;
  return (
    <span key={label}>
      <strong>{label}:</strong> {value}
    </span>
  );
};

export const ListedComponent = ({ data, selectedRoles, setSelectedRoles }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);


  const resetSelection = () => {
    setSelectedRoles([]);
  };

  const filteredData = data.filter(({ int_role , country, plant,type_of_request,type_of_sku,zone}) =>
    [int_role?.name, plant, country,type_of_request,type_of_sku,zone]
  .some((field) => field?.toLowerCase().includes(searchTerm.toLowerCase()))
);


  const sortedData = filteredData.sort((a, b) => {
    const nameA = a.int_role?.name?.toLowerCase() || '';
    const nameB = b.int_role?.name?.toLowerCase() || '';
    return nameA.localeCompare(nameB);
  });

  const displayedData = showSelectedOnly
    ? sortedData.filter(({ id }) => selectedRoles.includes(id))
    : sortedData;

  return (
    <div>
      <TextInput
        placeholder='Search'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.currentTarget.value)}
      />

      <Wrapper>
        {displayedData.length > 0 ? (
          displayedData.map(
            ({ int_role, country, plant, type_of_request, type_of_sku, zone, id }, index) => {
              const detailsArray = [
                renderLabelValue('Plant', plant),
                renderLabelValue('Country', country),
                renderLabelValue('Type of Request', type_of_request),
                renderLabelValue('Type of SKU', type_of_sku),
                renderLabelValue('Zone', zone),
              ].filter(Boolean);

              return (
                <div key={index}>
                  <Group noWrap>
                    <div style={{ flex: 1 }}>
                      <RoleName size='sm'>{int_role.name}</RoleName>
                      <RoleDetails>
                        {detailsArray.map((detail, idx) => (
                          <span key={idx}>
                            {detail}
                            {idx < detailsArray.length - 1 && ' | '}
                          </span>
                        ))}
                      </RoleDetails>
                    </div>
                  </Group>
                  <Space h='md' />
                </div>
              );
            },
          )
        ) : (
          <Text size='sm' color='dimmed'>
            No results found
          </Text>
        )}
      </Wrapper>
    </div>
  );
};
