import { Modal } from '@mantine/core';
import { useMemo } from 'react';
import MantineTable from '../../../../common/table/mantine';

const getColumns = (type) => {
  switch (type) {
    case 'd2p':
      return [
        { accessorKey: 'prev_artwork_actually_needed', header: 'Is Artwork Actually needed?' },
        {
          accessorKey: 'prev_artwork_adaptation_actually_needed',
          header: 'Is Artwork Adaptation Actually needed?',
        },
        { accessorKey: 'prev_artwork_management', header: 'Artwork Management' },
        { accessorKey: 'prev_color_target_setting_done', header: 'Is Colour Target Setting Done?' },
      ];
    case 'zitec':
    default:
      return [
        { accessorKey: 'prev_technically_feasible', header: 'Is project technically feasible?' },
        { accessorKey: 'prev_trials_needed', header: 'Is trial needed?' },
      ];
  }
};

export const PreviewModal = ({ open, content, close, type }) => {
  const columns = useMemo(() => getColumns(type), [content]);

  return (
    <Modal size='50%' opened={open} onClose={close}>
      <MantineTable
        columns={columns}
        initialData={content}
        enablePagination={false}
        enableSorting={false}
        enableBottomToolbar={false}
        enableTopToolbar={false}
        loading={false}
        enableColumnFilters={false}
        enableColumnActions={false}
        mantineTableHeadProps={{
          sx: {
            '& tr': {
              backgroundColor: '#D8D8D8',
              '& .mantine-TableHeadCell-Content': {
                color: '#1E1E1E',
              },
            },
          },
        }}
      />
    </Modal>
  );
};
