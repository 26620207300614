import { Button, Grid, Modal } from '@mantine/core';
import MantineTable from '../../../../common/table/mantine';
import { useContext, useEffect, useMemo, useState } from 'react';
import { axios } from '../../../../utils/axios';
import { AppLoader } from '../../../../components/common/loader';
import { transformSkuData } from './helper';
import { useParams } from 'react-router-dom';
import { RfsContext } from '../../../../contexts/RfsContext';

export const ImpactedSkus = ({ open, material, material_id, close, showAltBoms, readOnly }) => {
  const { form, info } = useContext(RfsContext);
  const { rfsNumber } = useParams();
  const [impactedSkus, setImpactedSkus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeSkus, setActiveSkus] = useState({});
  const [submitLoader, setSubmitLoader] = useState(false);

  const { scImpactedSkus = [] } = form;

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    const getActiveSkus = () => {
      const active = {};
      scImpactedSkus.forEach((sku) => {
        if (sku.selected) active[`${sku.sku_number}$${sku.future_changeover_date}`] = true;
      });

      return active;
    };

    if (scImpactedSkus.length > 0) {
      const active = getActiveSkus();
      setActiveSkus(active);
    }
  }, [scImpactedSkus]);

  const fetch = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/supplier-changes/pmo_section/impacted_skus/${material}`);
      setImpactedSkus(transformSkuData(response.data.data));
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'SKUs',
        header: 'SKU Number',
      },
      {
        accessorKey: 'SKUDescription',
        header: 'SKU Description',
      },
      {
        accessorKey: 'Markets',
        header: 'Markets',
      },
      {
        accessorKey: 'EANNumbers',
        header: 'EAN Number',
      },
      {
        accessorKey: 'ChangeoverDate',
        header: 'First Changeover',
      },
      {
        header: 'Valid Till',
        id: 'valid_till',
        Cell: ({ row }) => {
          return (
            <Button
              onClick={() => showAltBoms({ open: true, boms: row.original.AlternativeBOMs || [] })}
              color='dark'
              fullWidth
              size='sm'
              radius='sm'
              className='add-supplier-button'
            >
              Valid Till
            </Button>
          );
        },
      },
    ],
    [],
  );

  const handleRowSelection = (data) => {
    if (readOnly) return;
    let currentValues = { ...activeSkus };
    currentValues = { ...data };

    setActiveSkus(currentValues);
  };

  const getPayload = () => {
    const impactedSkusMap = new Map();
    impactedSkus.forEach((sku) => {
      if (!impactedSkusMap.has(sku.uniqueId)) {
        impactedSkusMap.set(sku.uniqueId, sku);
      }
    });

    return Object.entries(activeSkus).reduce((payload, [sku, isSelected]) => {
      const [skuNumber, futureChangeoverDate] = sku.split('$');
      const impactedSku = impactedSkusMap.get(sku) || {};
      const altBoms = impactedSku.AlternativeBOMs || [];

      altBoms.forEach((boms) => {
        payload.push({
          material_id: material_id,
          sku_number: skuNumber,
          future_changeover_date: futureChangeoverDate,
          selected: isSelected,
          plant: boms.Plant,
          alt_bom: boms.AlternativeBOM,
        });
      });

      return payload;
    }, []);
  };

  const handleSubmit = async () => {
    try {
      setSubmitLoader(true);
      const payload = getPayload();

      const skus = scImpactedSkus.filter((sku) => sku.material_id !== material_id);
      await axios.post(`/supplier-changes/impacted_skus/${rfsNumber}`, [...payload, ...skus]);
      info(rfsNumber);
      close();
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitLoader(false);
    }
  };

  return (
    <>
      <Modal size={'70%'} opened={open} title='Impacted SKUs' onClose={() => close()}>
        <MantineTable
          unique={'uniqueId'}
          columns={columns}
          initialData={
            readOnly ? impactedSkus.filter((sku) => activeSkus[sku.uniqueId]) : impactedSkus
          }
          hideSelectColumn={readOnly}
          handleRowSelection={handleRowSelection}
          activeRows={readOnly ? undefined : activeSkus}
          enableSorting={true}
          enableRowSelection={!readOnly}
          enableTopToolbar={false}
          loading={loading}
          enableColumnActions={false}
          mantineTableHeadProps={{
            sx: {
              '& tr': {
                backgroundColor: '#D8D8D8',

                '& .mantine-TableHeadCell-Content': {
                  color: '#1E1E1E',
                },
              },
            },
          }}
        />

        {!readOnly && (
          <Grid justify='flex-end' style={{ marginTop: '16px' }}>
            <Grid.Col span={2}>
              <Button
                disabled={Object.keys(activeSkus).length === 0 || submitLoader}
                color='dark'
                fullWidth
                onClick={() => handleSubmit()}
              >
                {loading || submitLoader ? <AppLoader size='sm' variant='dark' /> : 'Confirm'}
              </Button>
            </Grid.Col>
          </Grid>
        )}
      </Modal>
    </>
  );
};
