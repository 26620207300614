import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import MantineTable from '../../../../common/table/mantine';
import { Select, TextInput, Button, Skeleton } from '@mantine/core';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import { RfsContext } from '../../../../contexts/RfsContext';
import { NpsComponent } from '../../../../common/nps';
import { NpsIds, rfsStatus, techFeasibilityPermissions } from '../../../../constants';
import { ImpactedSkus } from './modal';
import { AuthContext } from '../../../../contexts/AuthContext';
import { CustomTextArea } from '../../../../common/inputs/CustomTextArea';
import { PreviewModal } from './previewModal';
import { SupplierList } from '../../scope/helper';

const InitialPreviewModal = {
  open: false,
  content: null,
  type: null,
};

export const Zitec = () => {
  const { rfsNumber } = useParams();
  const { form: rfsInfo, info: fetchRfsInfo } = useContext(RfsContext);
  const { userRole } = useContext(AuthContext);

  const [initialData, setInitialData] = useState(rfsInfo.zitecFeedback);
  const [form, setForm] = useState({});
  const [activePlants, setActivePlants] = useState({});
  const [loading, setLoading] = useState(false);
  const [npsOpened, setNpsOpened] = useState(false);
  const [impactedSkus, showImpactedSkus] = useState({ open: false, material: null, plant: null });
  const [previewModal, setPreviewModal] = useState(InitialPreviewModal);
  const [loaders, setLoaders] = useState({});

  const permissions = techFeasibilityPermissions['Supplier Change'];
  const readOnly = !permissions.zitec_approval.includes(userRole);

  const status = rfsInfo.rfsInformation.status;
  const formRef = useRef(form);
  formRef.current = form;

  useEffect(() => {
    if (rfsInfo.zitecFeedback.length > 0) {
      const currentValues = rfsInfo.zitecFeedback.reduce((acc, item) => {
        acc[item.id] = formRef.current[item.id]
          ? { ...item, ...formRef.current[item.id] }
          : { ...item };

        return acc;
      }, {});
      setInitialData(rfsInfo.zitecFeedback);
      setForm(currentValues);
    }
  }, [rfsInfo, loading]);

  const resetTableData = () => {
    const currentValues = { ...formRef.current };
    Object.keys(currentValues).forEach((id) => {
      if (!currentValues[id].updated_by) {
        currentValues[id] = {
          ...currentValues[id],
          technically_feasible: null,
          trials_needed: null,
          comment: '',
        };
      }
    });
    setForm(currentValues);
  };

  const handleChange = (value, type, row) => {
    if (row.getIsSelected()) {
      const currentValues = { ...formRef.current };
      const rowId = row.original.id;

      if (!currentValues[rowId]) {
        currentValues[rowId] = {};
      }

      currentValues[rowId][type] = value;

      if (type === 'technically_feasible' && value === 0) {
        currentValues[rowId].trials_needed = '';
      }

      setForm(currentValues);
    }
  };

  const handleSubmit = (info) => {
    const payload = {
      ...form[info.id],
      material_id: info.material_id,
      supplier_id: info.supplier_id,
    };
    setLoaders({ ...loaders, [info.id]: true });
    axios
      .put(
        `/supplier-changes/zitec/${info.updated_by ? 'pmo-submit' : 'submit'}/${rfsNumber}`,
        payload,
      )
      .then(
        () => {
          fetchRfsInfo(rfsNumber);
          setNpsOpened(true);
          const plants = { ...activePlants, [info.id]: false };
          const filteredPlants = Object.keys(plants).reduce((acc, key) => {
            if (plants[key]) {
              acc[key] = true;
            }
            return acc;
          }, {});

          setActivePlants(filteredPlants);
        },
        (error) => {
          console.error(error);
        },
      )
      .finally(() => {
        setLoaders({ ...loaders, [info.id]: false });
      });
  };

  const isDisabled = (id) => {
    const currentInfo = formRef.current[id];
    if (
      currentInfo.technically_feasible === undefined ||
      currentInfo.technically_feasible === null ||
      (typeof currentInfo.technically_feasible === 'string' &&
        currentInfo.technically_feasible.length === 0)
    ) {
      return true;
    }

    return currentInfo.technically_feasible === 1 && ![0, 1].includes(currentInfo.trials_needed);
  };

  const isPmoFieldsDisabled = () =>
    (!permissions.pmo_approval.includes(userRole) && readOnly) ||
    status !== rfsStatus.supplier_pmo2_active;

  const editableByPmo = ({ updated_by, updated_by_pmo }) =>
    !permissions.pmo_approval.includes(userRole) && readOnly;

  const handleRowSelection = (data) => {
    setActivePlants({ ...data });
  };

  const handleDefaultSet = (value, name) => {
    const currentValues = { ...formRef.current };
    const parsedValue = name === 'comment' ? value : parseInt(value);

    Object.keys(activePlants).forEach((material) => {
      if (activePlants[material]) {
        currentValues[material][name] = parsedValue;
      }
    });

    setForm(currentValues);
  };

  const columns = useMemo(
    () => [
      { accessorKey: 'material_code', header: 'Material Code' },
      { accessorKey: 'material_description', header: 'Material Description' },
      {
        accessorKey: 'plant',
        header: 'Plant',
        Cell: ({ row }) => <SupplierList suppliers={(row.original.plant || '').split(',')} />,
      },
      {
        header: 'SKUs using the material',
        id: 'impacted_skus',
        Cell: ({ row }) => (
          <Button
            color='dark'
            fullWidth
            size='sm'
            radius='sm'
            className='add-supplier-button'
            onClick={() =>
              showImpactedSkus({
                open: true,
                material: row.original.material_code,
                plant: row.original.plant,
              })
            }
          >
            Impacted SKUs
          </Button>
        ),
      },
      {
        accessorKey: 'current_suppliers',
        header: 'Current Supplier',
        Cell: ({ row }) => (
          <div>
            <SupplierList suppliers={(row.original.current_suppliers || '').split(',')} />
          </div>
        ),
      },

      {
        accessorKey: 'to_be_supplier',
        header: 'To be supplier',
      },
      {
        header: 'Is the project technically feasible?',
        accessorKey: 'technically_feasible',
        enableEditing: true,
        editVariant: 'select',
        mantineEditSelectProps: {
          data: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
        },
        Cell: ({ row }) => (
          <Skeleton visible={loaders[row.original.id] || false}>
            <Select
              size='sm'
              radius='md'
              onChange={(value) => handleChange(value, 'technically_feasible', row)}
              clearable
              data={[
                { label: 'Yes', value: 1 },
                { label: 'No', value: 0 },
              ]}
              disabled={!row.getIsSelected() || editableByPmo(row.original) || readOnly}
              value={formRef.current[row.original.id]?.technically_feasible}
              withinPortal
            />
          </Skeleton>
        ),
      },
      {
        header: 'Is trial needed?',
        accessorKey: 'trials_needed',
        enableEditing: true,
        editVariant: 'select',
        mantineEditSelectProps: {
          data: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
        },
        Cell: ({ row }) => (
          <Skeleton visible={loaders[row.original.id] || false}>
            <Select
              size='sm'
              radius='md'
              onChange={(value) => handleChange(value, 'trials_needed', row)}
              clearable
              data={[
                { label: 'Yes', value: 1 },
                { label: 'No', value: 0 },
              ]}
              disabled={
                !row.getIsSelected() ||
                editableByPmo(row.original) ||
                !formRef.current[row.original.id]?.technically_feasible ||
                formRef.current[row.original.id]?.technically_feasible === 0 ||
                readOnly
              }
              value={formRef.current[row.original.id]?.trials_needed}
              withinPortal
            />
          </Skeleton>
        ),
      },
      {
        header: 'Comment',
        accessorKey: 'comment',
        enableEditing: true,
        editVariant: 'text',
        Cell: ({ row }) => (
          <Skeleton visible={loaders[row.original.id] || false}>
            <CustomTextArea
              radius='md'
              onChange={(value) => handleChange(value, 'comment', row)}
              disabled={!row.getIsSelected() || row.original.updated_by || readOnly}
              value={formRef.current[row.original.id]?.comment || ''}
            />
          </Skeleton>
        ),
      },
      {
        header: 'Updated by PMO',
        id: 'updated_by_pmo',
        Cell: ({ row }) => (
          <Skeleton visible={loaders[row.original.id] || false}>
            <TextInput
              radius='md'
              onChange={(event) => handleChange(event.target.value, 'comment', row)}
              style={{ input: { width: '50px', height: '1rem' }, width: '20rem' }}
              disabled
              value={formRef.current[row.original.id]?.updated_by_pmo || ''}
            />
          </Skeleton>
        ),
      },
      {
        header: 'PMO comments',
        id: 'pmo_comments',
        Cell: ({ row }) => (
          <Skeleton visible={loaders[row.original.id] || false}>
            <CustomTextArea
              radius='md'
              onChange={(value) => handleChange(value, 'pmo_comments', row)}
              value={formRef.current[row.original.id]?.pmo_comments || ''}
              maxLength={500}
              disabled={!row.getIsSelected() || isPmoFieldsDisabled()}
            />
          </Skeleton>
        ),
      },
      {
        header: 'Previous ZITEC response',
        id: 'previous_zitec_response',
        Cell: ({ row }) => {
          const { prev_technically_feasible, prev_trials_needed } = row.original;

          const validate = (data) => {
            return ['Yes', 'No'].includes(data);
          };

          const getContent = () => {
            return [{ prev_technically_feasible, prev_trials_needed }].filter(
              (val) => validate(val.prev_technically_feasible) || validate(val.prev_trials_needed),
            );
          };

          return (
            <Skeleton visible={loaders[row.original.id] || false}>
              <Button
                onClick={() =>
                  setPreviewModal({
                    open: true,
                    content: getContent(),
                    type: 'zitec',
                  })
                }
                color='dark'
                fullWidth
                size='sm'
                radius='sm'
                disabled={isPmoFieldsDisabled()}
                className='add-supplier-button'
              >
                View
              </Button>
            </Skeleton>
          );
        },
      },
      {
        header: 'Action',
        id: 'action',
        Cell: ({ row }) => (
          <Skeleton visible={loaders[row.original.id] || false}>
            <Button
              onClick={() => handleSubmit(row.original)}
              color='dark'
              fullWidth
              size='sm'
              radius='sm'
              disabled={
                !row.getIsSelected() ||
                editableByPmo(row.original) ||
                isDisabled(row.original.id) ||
                readOnly
              }
              className='add-supplier-button'
            >
              Submit
            </Button>
          </Skeleton>
        ),
      },
    ],
    [form, activePlants, rfsInfo, loaders],
  );

  return (
    <div>
      <MantineTable
        columns={columns}
        initialData={initialData}
        unique='id'
        handleRowSelection={handleRowSelection}
        activeRows={activePlants}
        enableRowSelection
        loading={loading}
        resetTableData={resetTableData}
        editAll
        applyToAll={handleDefaultSet}
        showSelectedToggle
        showResetAll
        hideSelectColumn={false}
        enablePinning
        initialState={{
          sorting: [{ id: 'material_code', desc: false }],
          showColumnFilters: true,
          density: 'xs',
          columnPinning: {
            left: ['mrt-row-select'],
            right: [''],
          },
        }}
      />

      {npsOpened && (
        <NpsComponent
          rfs_number={rfsNumber}
          opened={npsOpened}
          setOpened={setNpsOpened}
          callback={() => setNpsOpened(false)}
          id={NpsIds().technically_feasible}
        />
      )}

      {impactedSkus.open && <ImpactedSkus state={impactedSkus} setState={showImpactedSkus} />}
      {previewModal.open && (
        <PreviewModal close={() => setPreviewModal(InitialPreviewModal)} {...previewModal} />
      )}
    </div>
  );
};
