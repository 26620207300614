import React, { useEffect, useState, useContext } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import {
  Button,
  Tooltip,
  Switch,
  TextInput,
  Select,
  MantineProvider,
  useMantineTheme,
} from '@mantine/core';
import {
  IconSearch,
  IconSearchOff,
  IconEditCircle,
  IconEditCircleOff,
  IconRefresh,
} from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { RfsContext } from '../../contexts/RfsContext';
import { AuthContext } from '../../contexts/AuthContext';
import ContentPaper from '../layouts/rfs/Content';

//CSV Download
import csvDownload from 'json-to-csv-export';
import { TableExport } from 'tabler-icons-react';
import moment from 'moment';
import { DownloadExcel } from '../../common/excel';
import { TableSearch } from '../../common/table/mantine/search';
import { dateFormat } from '../../constants';
import { convertDateFormat } from '../../utils/helpers';

const RawdataReport = ({
  columns,
  setValidationErrors,
  statusData,
  unique,
  enableEditing,
  readOnly,
}) => {
  const [switchChecked, setSwitchChecked] = useState(false);
  const [selected, setSelected] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const { userName } = useContext(AuthContext);
  const [filteredRows, setFilteredRows] = useState([]);
  const globalTheme = useMantineTheme;

  //Custom search
  const [toBeSearched, setToBeSearched] = useState('');

  //Search Icon
  const [searchOn, setSearchOn] = useState(false);
  const [allEditOn, setAllEditOn] = useState(false);

  //AllEdit
  const [toBeEditedValue, setToBeEditedValue] = useState({});
  const [selectType, setSelectType] = useState('');

  //All Select
  const [allSelected, setAllSelected] = useState(false);

  const [data, setData] = useState([]);
  const [successData, setSuccessData] = useState([]);

  //const data = switchChecked && Object.keys(selected).length ? selected : reportData;
  const { fetchRawdata } = useContext(RfsContext);

  //READ hook (get data in api)
  function useGet() {
    return useQuery({
      queryKey: ['fetchRawdata'],
      queryFn: fetchRawdata,
      refetchOnWindowFocus: false,
    });
  }

  const { data: fetchedData = [], isError, isFetching, isLoading, error, status } = useGet();

  useEffect(() => {
    if (status === 'success' && !isFetching) {
      let data = fetchedData.data.data;
      data = convertDateFormat(data);
      setData(data);
      setSuccessData(data);
      setFilteredRows(data);
    }
  }, [status, isFetching]);

  const validateRequired = (value) => !!value?.length;

  function validate(items) {
    return {
      Status: !validateRequired(items.Status) ? 'Status is Required' : '',
    };
  }

  const readyExport = () => {
    let exportdata = [...data];

    exportdata = exportdata.map((info) => ({
      'Submission Date': info.submission_date,
      'RFS ID': info.rfs_id,
      'RFS Name': info.rfs_name,
      'Approval Date': info.approval_date,
      'Implementation Date': info.Implementation_date,
      'RFS Type': info.rfs_type,
      Gatekeeper: info.gatekeeper,
      'Initiator Name': info.initiator_name,
      'RFS Status': info.rfs_status,
      Confidential: info.Confidential,
      Fasttrack: info.fast_track,
      'SKU Number': info.sku_number,
      'SKU Description': info.sku_description,
      brand: info.brand,
      'Sub Brand': info.SubBrand,
      Container: info.Container,
      Markets: info.Markets,
      Maco: info.Maco,
      'First Forcast': info.first_forcast,
      'First Forcast Week': info.first_forcast_week,
      'Additional Markets': info.additional_markets,
      Plant: info.plant,
      'Additional Plants': info.additional_plants,
      'First Filling Plan Vol': info.first_filling_plan_vol,
      'First Filling Plan Week': info.first_filling_plan_week,
      FSD: info.FSD,
      'Material Number': info.material_number,
      'Material Description': info.material_description,
      'Supplier Name': info.SupplierName,
      'GFP Confirmation': info.GFP_Confirmation,
      Obsoletes: info.obsoletes,
      'Reference SKU Number': info.reference_sku_number,
      'Reference SKU Description': info.reference_sku_description,
      'Production Buisness Unit': info.reference_sku_description,
      'Sales Business Unit': info.sales_business_unit,
      'Cost Center': info.cost_center,
    }));

    return exportdata;
  };

  const filterMultiSearch = (input, filteredData) => {
    setData(
      input.length > 0
        ? filteredData
        : switchChecked && Object.keys(selected).length
        ? selected
        : successData,
    );
    setFilteredRows(input.length > 0 ? filteredData : successData);
  };

  const resetAll = () => {
    setSearchOn(false);
    setAllSelected(false);
    setRowSelection({});
    setAllEditOn(false);
    setSwitchChecked(false);
    setData(successData);
    setFilteredRows(successData);
  };

  const table = useMantineReactTable({
    columns,
    data: data,
    createDisplayMode: 'row',
    editDisplayMode: 'row',
    enableEditing: enableEditing,
    getRowId: (row) => row[unique],
    enableRowSelection: true,
    selectDisplayMode: 'checkbox',
    enableGlobalFilter: false,
    enableSelectAll: true,
    selectAllMode: 'all',
    initialState: {
      showColumnFilters: true,
      columnVisibility: { data_id: false },
    },
    mantineToolbarAlertBannerProps: isError
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),

    renderTopToolbarCustomActions: ({ table }) => {
      const setUpEditedValues = (e) => {
        setToBeEditedValue({ ...toBeEditedValue, ['selectedColumn']: e.split('|')[0] });
        setSelectType(e.split('|')[1]);
      };

      const editableColumns = [
        {
          accessorKey: 'Status',
          header: 'Rawdata Status',
          editVariant: 'select',
          id: 'Status',
        },
      ];

      return (
        <>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Tooltip label='Show All Selected'>
              <Switch
                disabled={selected.length === 0 && !switchChecked}
                checked={switchChecked}
                onChange={(event) => setSwitchChecked(event.currentTarget.checked)}
              />
            </Tooltip>

            <Tooltip label='Reset All'>
              <IconRefresh onClick={resetAll} />
            </Tooltip>

            <div
              onClick={() => {
                if (searchOn) setFilteredRows(successData);
                setSearchOn(searchOn ? false : true);
              }}
            >
              <Tooltip label='Search All'>{searchOn ? <IconSearchOff /> : <IconSearch />}</Tooltip>
            </div>

            {Object.keys(rowSelection).length > 0 && enableEditing && (
              <Tooltip label='Edit All Selected'>
                <div onClick={() => setAllEditOn(allEditOn ? false : true)}>
                  {allEditOn ? <IconEditCircleOff /> : <IconEditCircle />}
                </div>
              </Tooltip>
            )}
          </div>

          {searchOn && (
            <TableSearch
              unique={unique}
              columns={columns}
              toBeSearched={toBeSearched}
              setToBeSearched={setToBeSearched}
              filterMultiSearch={filterMultiSearch}
              data={successData}
            />
          )}

          {allEditOn && (
            <div className='edit-Group'>
              <div className='searchGroup' style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Select
                  disabled={readOnly}
                  label='Select The Column To Edit'
                  placeholder='Select The Column'
                  data={editableColumns.map((column) => {
                    return {
                      value: column.accessorKey + '|' + column.editVariant,
                      label: column.header,
                      disabled: column.enableEditing != undefined ? !column.enableEditing : false,
                    };
                  })}
                  onChange={(e) => setUpEditedValues(e)}
                />
                {selectType && selectType === 'select' ? (
                  <Select
                    label='Select'
                    placeholder='Select'
                    disabled={readOnly}
                    data={statusData.map((item) => item)}
                    onChange={(value) =>
                      setToBeEditedValue({
                        ...toBeEditedValue,
                        ['value']: value,
                      })
                    }
                  />
                ) : (
                  <TextInput
                    disabled={readOnly}
                    clearable
                    label='&nbsp;'
                    placeholder='Edit'
                    onKeyUp={(event) =>
                      setToBeEditedValue({
                        ...toBeEditedValue,
                        ['value']: event.target.value,
                      })
                    }
                  />
                )}
              </div>
            </div>
          )}
        </>
      );
    },
    state: {
      isLoading: isLoading,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
  });

  //Selection Toggle
  const updateSelected = () => {
    var filtered = filteredRows.filter((item, index) => {
      return Object.keys(rowSelection).indexOf(item[unique]) !== -1;
    });

    const currentValues = selected;
    currentValues.forEach((val) => {
      const index = filtered.findIndex((item) => item[unique] === val[unique]);
      if (index === -1) filtered.push(val);
    });

    setSelected(filtered);
  };

  useEffect(() => {
    if (switchChecked || allSelected) setData(selected);
    else if ((!switchChecked || !allSelected) && !searchOn) setData(successData);
    else if ((!switchChecked || !allSelected) && searchOn) setData(filteredRows);
    else setData(successData);
  }, [switchChecked, selected, data, searchOn]);

  useEffect(() => {
    updateSelected();
  }, [rowSelection]);

  if (isError) {
    return (
      <ContentPaper page={'Rawdata'}>
        <span>{error.message}</span>
      </ContentPaper>
    );
  }

  return (
    <>
      <DownloadExcel
        fetch={() => readyExport()}
        filename={`Download_Report_${moment().format('YYYY-MM-DD HH:mm:ss')}`}
      />
      <MantineProvider theme={{ ...globalTheme, primaryColor: 'yellow', primaryShade: 7 }}>
        <MantineReactTable table={table} />
      </MantineProvider>
    </>
  );
};

export default RawdataReport;
