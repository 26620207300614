import React, { useEffect, useState } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import {
  Tooltip,
  Switch,
  TextInput,
  Select,
  MantineProvider,
  useMantineTheme,
} from '@mantine/core';
import { IconSearch, IconSearchOff, IconRefresh } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import ContentPaper from '../layouts/rfs/Content';
import { axios } from '../../utils/axios';

import moment from 'moment';
import { DownloadExcel } from '../../common/excel';
import { TableSearch } from '../../common/table/mantine/search';
import { dateFormat } from '../../constants';
import { convertDateFormat } from '../../utils/helpers';

const BottleneckReport = ({ columns, unique }) => {
  const [switchChecked, setSwitchChecked] = useState(false);
  const [selected, setSelected] = useState([{}]);
  const [rowSelection, setRowSelection] = useState({});
  const [filteredRows, setFilteredRows] = useState([]);
  const globalTheme = useMantineTheme;

  //Custom search
  const [toBeSearched, setToBeSearched] = useState('');

  //Search Icon
  const [searchOn, setSearchOn] = useState(false);

  //All Select
  const [allSelected, setAllSelected] = useState(false);

  const [data, setData] = useState([]);
  const [successData, setSuccessData] = useState([]);

  const fetchBottleneck = () => {
    return axios({ url: 'reports/bottleneck', timeout: 30000 });
  };

  const replaceEmptyHeaders = (data) => {
    const updatedData = [];

    data.forEach((group) => {
      group.columns.forEach((column) => {
        let updatedHeader = column.header;

        // Check if the accessorKey contains "startDate," "dueDate," or "assignee"
        if (
          column.accessorKey.includes('StartDate') ||
          column.accessorKey.includes('DueDate') ||
          column.accessorKey.includes('Assignee')
        ) {
          // Append the group header at the start of the header
          updatedHeader = group.header + ' ' + column.header;
        }

        // Push the new object into the updatedData array
        updatedData.push({
          header: updatedHeader,
          accessorKey: column.accessorKey,
        });
      });
    });

    return updatedData;
  };

  const updatedColumns = replaceEmptyHeaders(columns);

  //READ hook (get data in api)
  function useGet() {
    return useQuery({
      queryKey: ['fetchBottleneck'],
      queryFn: fetchBottleneck,
      refetchOnWindowFocus: false,
    });
  }

  //call READ hook
  // let successData = [];
  const { data: fetchedData = [], isError, isFetching, isLoading, error, status } = useGet();

  useEffect(() => {
    if (status === 'success' && !isFetching) {
      let data = fetchedData.data.data;
      data = convertDateFormat(data);
      setData(data);
      setSuccessData(data);
      setFilteredRows(data);
    }
  }, [status, isFetching]);

  const readyExport = () => {
    let reportData = [...data];

    reportData = reportData.map((item) => ({
      'RFS ID': item.rfs_id,
      'RFS Name': item.rfs_name,
      'RFS Status': item.rfs_status,
      'RFS Type': item.rfs_type,
      'Fast Track': item.fast_track,
      'First Shipment Date': item.FSD,
      'Submission Date': item.submission_date,
      Gatekeeper: item.gatekeeper,
      'Production Business Unit': item.ProductionBusinessUnit,
      'Sales Business Unit': item.sales_business_unit,
      Plants: item.Plants,
      Markets: item.Markets,
      'SKU RFS Volume': item.sku_rfs_volume,
      'RFS Volume': item.rfs_volume,
      'SKU Number': item.sku_number,
      'SKU Description': item.sku_description,
      'Material Number': item.material_number,
      'Material Description': item.material_description,
      'Material Group': item.MaterialGroup,
      'Steps In Progress': item.StepsInProgress,
      'SKU Creation Start Date': item.StartDate1
        ? moment(item.StartDate1).format(dateFormat)
        : 'Not Started',
      'SKU Creation Due Date': item.DueDate1 ? moment(item.DueDate1).format(dateFormat) : '/',
      'SKU Creation Assignee': item.Assignee1,
      'Supplier Name Start Date': item.StartDate2
        ? moment(item.StartDate2).format(dateFormat)
        : 'Not Started',
      'Supplier Name Due Date': item.DueDate2 ? moment(item.DueDate2).format(dateFormat) : '/',
      'Supplier Name Assignee': item.Assignee2,
      'SKU Status 40 Start Date': item.StartDate3
        ? moment(item.StartDate3).format(dateFormat)
        : 'Not Started',
      'SKU Status 40 Due Date': item.DueDate3 ? moment(item.DueDate3).format(dateFormat) : '/',
      'SKU Status 40 Assignee': item.Assignee3,
      'Demand Planning Start Date': item.StartDate4
        ? moment(item.StartDate4).format(dateFormat)
        : 'Not Started',
      'Demand Planning Due Date': item.DueDate4 ? moment(item.DueDate4).format(dateFormat) : '/',
      'Demand Planning Assignee': item.Assignee4,
      'Production Version Start Date': item.StartDate5
        ? moment(item.StartDate5).format(dateFormat)
        : 'Not Started',
      'Production Version Due Date': item.DueDate5 ? moment(item.DueDate5).format(dateFormat) : '/',
      'Production Version Assignee': item.Assignee5,
      'Artwork Development Start Date': item.StartDate6
        ? moment(item.StartDate6).format(dateFormat)
        : 'Not Started',
      'Artwork Development Due Date': item.DueDate6
        ? moment(item.DueDate6).format(dateFormat)
        : '/',
      'Artwork Development Assignee': item.EndDate6,
      'EAN Approval Start Date': item.StartDate7
        ? moment(item.StartDate7).format(dateFormat)
        : 'Not Started',
      'EAN Approval Due Date': item.DueDate7 ? moment(item.DueDate7).format(dateFormat) : '/',
      'EAN Approval Assignee': item.Assignee7,
      'Materials Contract Start Date': item.StartDate8
        ? moment(item.StartDate8).format(dateFormat)
        : 'Not Started',
      'Materials Contract Due Date': item.DueDate8 ? moment(item.DueDate8).format(dateFormat) : '/',
      'Materials Contract Assignee': item.Assignee8,
      'Standard Price Start Date': item.StartDate9
        ? moment(item.StartDate9).format(dateFormat)
        : 'Not Started',
      'Standard Price Due Date': item.DueDate9 ? moment(item.DueDate9).format(dateFormat) : '/',
      'Standard Price Assignee': item.Assignee9,
      'Status 99 Start Date': item.StartDate10
        ? moment(item.StartDate10).format(dateFormat)
        : 'Not Started',
      'Status 99 Due Date': item.DueDate10 ? moment(item.DueDate10).format(dateFormat) : '/',
      'Status 99 Assignee': item.Assignee10,
      'Cost Collector Start Date': item.StartDate11
        ? moment(item.StartDate11).format(dateFormat)
        : 'Not Started',
      'Cost Collector Due Date': item.DueDate11 ? moment(item.DueDate11).format(dateFormat) : '/',
      'Cost Collector Assignee': item.Assignee11,
      'Market Extension Start Date': item.StartDate12
        ? moment(item.StartDate12).format(dateFormat)
        : 'Not Started',
      'Market Extension Due Date': item.DueDate12 ? moment(item.DueDate12).format(dateFormat) : '/',
      'Market Extension Assignee': item.Assignee12,
      'Vendor Open Start Date': item.StartDate13
        ? moment(item.StartDate13).format(dateFormat)
        : 'Not Started',
      'Vendor Open Due Date': item.DueDate13 ? moment(item.DueDate13).format(dateFormat) : '/',
      'Vendor Open Assignee': item.Assignee13,
      'VIC Check Start Date': item.StartDate14
        ? moment(item.StartDate14).format(dateFormat)
        : 'Not Started',
      'VIC Check Due Date': item.DueDate14 ? moment(item.DueDate14).format(dateFormat) : '/',
      'VIC Check Assignee': item.Assignee14,
      'VLC Check Start Date': item.StartDate15
        ? moment(item.StartDate15).format(dateFormat)
        : 'Not Started',
      'VLC Check Due Date': item.DueDate15 ? moment(item.DueDate15).format(dateFormat) : '/',
      'VLC Check Assignee': item.Assignee15,
      'Transfer Price Start Date': item.StartDate16
        ? moment(item.StartDate16).format(dateFormat)
        : 'Not Started',
      'Transfer Price Due Date': item.DueDate16 ? moment(item.DueDate16).format(dateFormat) : '/',
      'Transfer Price Assignee': item.Assignee16,
      'Material Code Creation Start Date': item.StartDate17
        ? moment(item.StartDate17).format(dateFormat)
        : 'Not Started',
      'Material Code Creation Due Date': item.DueDate17
        ? moment(item.DueDate17).format(dateFormat)
        : '/',
      'Material Code Creation Assignee': item.Assignee17,
      'Production Plant Extension Start Date': item.StartDate18
        ? moment(item.StartDate18).format(dateFormat)
        : 'Not Started',
      'Production Plant Extension Due Date': item.DueDate18
        ? moment(item.DueDate18).format(dateFormat)
        : '/',
      'Production Plant Extension Assignee': item.Assignee18,
      'Material Status Change Start Date': item.StartDate19
        ? moment(item.StartDate19).format(dateFormat)
        : 'Not Started',
      'Material Status Change Due Date': item.DueDate19
        ? moment(item.DueDate19).format(dateFormat)
        : '/',
      'Material Status Change Assignee': item.Assignee19,
      'SKU Planning Start Date': item.StartDate20
        ? moment(item.StartDate20).format(dateFormat)
        : 'Not Started',
      'SKU Planning Due Date': item.DueDate20 ? moment(item.DueDate20).format(dateFormat) : '/',
      'SKU Planning Assignee': item.Assignee20,
      'Material Pricing Start Date': item.StartDate21
        ? moment(item.StartDate21).format(dateFormat)
        : 'Not Started',
      'Material Pricing Due Date': item.DueDate21 ? moment(item.DueDate21).format(dateFormat) : '/',
      'Material Pricing Assignee': item.Assignee21,
      'Supplier Production Start Date': item.StartDate22
        ? moment(item.StartDate22).format(dateFormat)
        : 'Not Started',
      'Supplier Production Due Date': item.DueDate22
        ? moment(item.DueDate22).format(dateFormat)
        : '/',
      'Supplier Production Assignee': item.Assignee22,
      'Material on Stock at Supplier Start Date': item.StartDate23
        ? moment(item.StartDate23).format(dateFormat)
        : 'Not Started',
      'Material on Stock at Supplier Due Date': item.DueDate23
        ? moment(item.DueDate23).format(dateFormat)
        : '/',
      'Material on Stock at Supplier Assignee': item.Assignee23,
      'Material on Stock at ABI Start Date': item.StartDate24
        ? moment(item.StartDate24).format(dateFormat)
        : 'Not Started',
      'Material on Stock at ABI Due Date': item.DueDate24
        ? moment(item.DueDate24).format(dateFormat)
        : '/',
      'Material on Stock at ABI Assignee': item.Assignee24,
      'BOM Availability Start Date': item.StartDate25
        ? moment(item.StartDate25).format(dateFormat)
        : 'Not Started',
      'BOM Availability Due Date': item.DueDate25 ? moment(item.DueDate25).format(dateFormat) : '/',
      'BOM Availability Assignee': item.Assignee25,
      'Material at Status 99 Start Date': item.StartDate26
        ? moment(item.StartDate26).format(dateFormat)
        : 'Not Started',
      'Material at Status 99 Due Date': item.DueDate26
        ? moment(item.DueDate26).format(dateFormat)
        : '/',
      'Material at Status 99 Assignee': item.Assignee26,
      'Procurement Price Start Date': item.StartDate27
        ? moment(item.StartDate27).format(dateFormat)
        : 'Not Started',
      'Procurement Price Due Date': item.DueDate27
        ? moment(item.DueDate27).format(dateFormat)
        : '/',
      'Procurement Price Assignee': item.Assignee27,
      'Repacking Fee Start Date': item.StartDate28
        ? moment(item.StartDate28).format(dateFormat)
        : 'Not Started',
      'Repacking Fee Due Date': item.DueDate28 ? moment(item.DueDate28).format(dateFormat) : '/',
      'Repacking Fee Assignee': item.Assignee28,
      'Filling and Shipment Start Date': item.StartDate29
        ? moment(item.StartDate29).format(dateFormat)
        : 'Not Started',
      'Filling and Shipment Due Date': item.DueDate29
        ? moment(item.DueDate29).format(dateFormat)
        : '/',
      'Filling and Shipment Assignee': item.Assignee29,
      'Trials Start Date': item.StartDate30
        ? moment(item.StartDate30).format(dateFormat)
        : 'Not Started',
      'Trials Due Date': item.DueDate30 ? moment(item.DueDate30).format(dateFormat) : '/',
      'Trials Assignee': item.Assignee30,
      'Status Check Start Date': item.StartDate31
        ? moment(item.StartDate31).format(dateFormat)
        : 'Not Started',
      'Status Check Due Date': item.DueDate31 ? moment(item.DueDate31).format(dateFormat) : '/',
      'Status Check Assignee': item.Assignee31,
      'Changeover Start Date': item.StartDate32
        ? moment(item.StartDate32).format(dateFormat)
        : 'Not Started',
      'Changeover Due Date': item.DueDate32 ? moment(item.DueDate32).format(dateFormat) : '/',
      'Changeover Assignee': item.Assignee32,
      'Obsoletes Approval MRP Start Date': item.StartDate33
        ? moment(item.StartDate33).format(dateFormat)
        : 'Not Started',
      'Obsoletes Approval MRP Due Date': item.DueDate33
        ? moment(item.DueDate33).format(dateFormat)
        : '/',
      'Obsoletes Approval MRP Assignee': item.Assignee33,
      'Status Change to AA Start Date': item.StartDate35
        ? moment(item.StartDate35).format(dateFormat)
        : 'Not Started',
      'Status Change to AA Due Date': item.DueDate35
        ? moment(item.DueDate35).format(dateFormat)
        : '/',
      'Status Change to AA Assignee': item.Assignee35,
      'Status Change to ZZ Start Date': item.StartDate36
        ? moment(item.StartDate36).format(dateFormat)
        : 'Not Started',
      'Status Change to ZZ Due Date': item.DueDate36
        ? moment(item.DueDate36).format(dateFormat)
        : '/',
      'Status Change to ZZ Assignee': item.Assignee36,
    }));

    return reportData;
  };

  const filterMultiSearch = (input, filteredData) => {
    setData(
      input.length > 0
        ? filteredData
        : switchChecked && Object.keys(selected).length
        ? selected
        : successData,
    );
    setFilteredRows(input.length > 0 ? filteredData : successData);
  };

  const resetAll = () => {
    setSearchOn(false);
    setAllSelected(false);
    setRowSelection({});
    setSwitchChecked(false);
    setData(successData);
    setFilteredRows(successData);
  };

  const table = useMantineReactTable({
    columns,
    data: data,
    createDisplayMode: 'row', // ('modal', and 'custom' are also available)
    editDisplayMode: 'row', // ('modal', 'cell', 'table', and 'custom' are also available)
    enableEditing: false,
    enableRowSelection: true,
    selectDisplayMode: 'checkbox',
    enableGlobalFilter: false,
    enablePinning: true,
    enableSelectAll: true,
    enableColumnResizing: true,
    selectAllMode: 'all',
    getRowId: (row) => row[unique],
    initialState: {
      density: 'xs',
      showColumnFilters: true,
    },
    mantineTableProps: {
      highlightOnHover: false,
      withColumnBorders: true,
      withBorder: 'light',
    },
    mantineToolbarAlertBannerProps: isError
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    renderTopToolbarCustomActions: ({ table }) => {
      return (
        <>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Tooltip label='Show All Selected'>
              <Switch
                disabled={selected.length === 0 && !switchChecked}
                checked={switchChecked}
                onChange={(event) => setSwitchChecked(event.currentTarget.checked)}
              />
            </Tooltip>

            <Tooltip label='Reset All'>
              <IconRefresh onClick={resetAll} />
            </Tooltip>

            <div onClick={() => setSearchOn(searchOn ? false : true)}>
              <Tooltip label='Search All'>{searchOn ? <IconSearchOff /> : <IconSearch />}</Tooltip>
            </div>
          </div>
          {searchOn && (
            <TableSearch
              unique={unique}
              columns={updatedColumns}
              toBeSearched={toBeSearched}
              setToBeSearched={setToBeSearched}
              filterMultiSearch={filterMultiSearch}
              data={successData}
            />
          )}
        </>
      );
    },
    state: {
      isLoading: isLoading,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
  });

  //Selection Toggle
  const updateSelected = () => {
    var filtered = filteredRows.filter((item, index) => {
      return Object.keys(rowSelection).indexOf(item[unique]) !== -1;
    });

    setSelected(filtered);
  };

  useEffect(() => {
    if (switchChecked || allSelected) setData(selected);
    else if ((!switchChecked || !allSelected) && !searchOn) setData(successData);
    else if ((!switchChecked || !allSelected) && searchOn) setData(filteredRows);
    else setData(successData);
  }, [switchChecked, selected, data, searchOn]);

  useEffect(() => {
    updateSelected();
  }, [rowSelection]);

  if (isError) {
    return (
      <ContentPaper page={'Decomplexity'}>
        <span>{error.message}</span>
      </ContentPaper>
    );
  }

  return (
    <>
      {/* <div
        className='downLoadBtn'
        style={{
          marginBottom: '1rem',
          marginTop: '2rem',
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        <Button
          leftIcon={<TableExport />}
          onClick={() => {
            downloadExcel();
          }}
          color='dark'
          style={{
            margin: '0',
          }}
        >
          Export Report to CSV
        </Button>
      </div> */}
      <DownloadExcel
        fetch={() => readyExport()}
        filename={`Bottleneck_Report_${moment().format('YYYY-MM-DD HH:mm:ss')}`}
      />
      <MantineProvider theme={{ ...globalTheme, primaryColor: 'yellow', primaryShade: 7 }}>
        <MantineReactTable table={table} />
      </MantineProvider>
    </>
  );
};

export default BottleneckReport;
