import moment from 'moment';

export const getPriceValue = (data) => {
  const currentYear = moment().year();

  // Check if planned_price_1 and planned_price_date_1 are present and in the current year
  if (
    data.planned_price_1 &&
    parseFloat(data.planned_price_1) > 0 &&
    data.planned_price_date_1 &&
    moment(data.planned_price_date_1).year() === currentYear
  ) {
    return parseFloat(data.planned_price_1);
  }

  // Check if standard_price and last_change_price are present and in the current year
  if (
    data.standard_price &&
    parseFloat(data.standard_price) > 0 &&
    data.last_change_price &&
    moment(data.last_change_price).year() === currentYear
  ) {
    return parseFloat(data.standard_price);
  }

  // Default to 0 if neither condition is met
  return 0;
};
