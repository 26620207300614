import { useMemo, useState } from 'react';
import { Button, Checkbox } from '@mantine/core';
import MantineTable from '../../../../common/table/mantine';
import styled from '@emotion/styled';
import { Switch } from '@mantine/core';
import { useSwitchStyles } from '../budgetBomAdjustment';

const CheckboxContainer = styled.div`
  margin: 16px 0;
`;

export const Table = ({
  skus = [],
  setBomsModal,
  selectedSkus,
  handleRowSelection,
  plantId,
  skuId,
  selectedSkusDetails,
  readOnly = false,
  allSelected = false,
}) => {
  const { classes } = useSwitchStyles();
  const [dualSkuSelection, setDualSkuSelection] = useState(false);
  const { selectedData = {} } = selectedSkusDetails || {};
  const [switchState, setSwitchState] = useState(false);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'type',
        header: 'Type of SKU',
      },
      {
        accessorKey: 'sku',
        header: 'SKU Number',
      },
      {
        accessorKey: 'sku_description',
        header: 'SKU Description',
      },
      {
        accessorKey: 'plant',
        header: 'Production Plant',
      },
      {
        accessorKey: 'brand',
        header: 'Brand',
      },
      {
        accessorKey: 'container',
        header: 'Pack Type',
      },
      {
        id: 'bom',
        header: 'Show BOM',
        Cell: ({ row }) => (
          <Button
            color='dark'
            onClick={() =>
              setBomsModal({
                open: true,
                sku: row.original.sku,
                plant: row.original.plant,
              })
            }
          >
            View BOM
          </Button>
        ),
      },
    ],
    [skus, selectedSkus],
  );

  const handleSelection = (data) => {
    handleRowSelection(data, plantId, skuId);
  };

  const isDisabled = (sku) => {
    const selectedDataLength = Object.keys(selectedData).length;
    return readOnly
      ? true
      : ((dualSkuSelection && selectedDataLength >= 2) ||
          (!dualSkuSelection && selectedDataLength >= 1)) &&
          !selectedData?.[sku.unique];
  };

  const leftButtons = [
    {
      button: (
        <Switch
          label='Show Recommended SKUs only'
          onChange={(event) => {
            setSwitchState(event.target.checked);
          }}
          classNames={{ input: classes.input, thumb: classes.thumb, track: classes.track }}
        />
      ),
    },
  ];
  return (
    <>
      <MantineTable
        columns={columns}
        initialData={(skus || []).filter(
          (ele) =>
            (switchState && (ele.type === 'RFS' || ele.type === 'Recommended')) || !switchState,
        )}
        unique={'unique'}
        enableRowSelection={true}
        loading={false}
        handleRowSelection={handleSelection}
        activeRows={selectedData}
        showSelectedToggle={true}
        hideSelectColumn={false}
        showResetAll={false}
        enableExport={true}
        pageSize={10}
        enableSelectAll={false}
        leftButtons={leftButtons}
        initialState={{
          sorting: [
            {
              id: 'sku_description',
              desc: false,
            },
          ],
          showColumnFilters: true,
        }}
        positionToolbarAlertBanner={'none'}
        isRowDisabled={(row) => isDisabled(row)}
      />

      {/* <CheckboxContainer>
        <Checkbox
          label='Dual Reference Selection'
          value={dualSkuSelection}
          disabled={allSelected || readOnly}
          onChange={(event) => {
            if (!event.target.checked) handleRowSelection({}, plantId, skuId);

            setDualSkuSelection(event.target.checked);
          }}
        />
      </CheckboxContainer> */}
    </>
  );
};
