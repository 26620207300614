import { useEffect, useMemo, useState } from 'react';
import MantineTable from '../../../common/table/mantine';
import { Tooltip, ActionIcon, Flex } from '@mantine/core';
import { IconEdit, IconTrash, IconUserCheck } from '@tabler/icons-react';
import { axios } from '../../../utils/axios';
import { RequestPreview } from './requestPreview';
import styled from '@emotion/styled';
import withConfirm from '../../../components/common/confirm-dialog';
import moment from 'moment';
import { getExistingRoles } from './helper';

const Link = styled.span`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
`;

const PendingUsers = ({ confirm, activeTab }) => {
  const [previewModal, setPreviewModal] = useState({ open: false, user: null });
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (activeTab === 'pending') fetch();
  }, [activeTab]);

  const fetch = async () => {
    try {
      setLoading(true);
      const existingUsersResponse = await axios.get('user/v2');
      const response = await axios.get('/user/v2/request');

      const existingRoles = getExistingRoles(existingUsersResponse?.data?.data || []);

      const data = response.data.data
        .filter((item) => item.status === 'Pending')
        .map((user) => {
          const requestedAt = user.requested_date
            ? moment(user.requested_date).format('MMMM D, YYYY')
            : 'N/A';

          return {
            id: user.id,
            Request_id: user.request_number,
            name: user.int_user.name,
            email: user.int_user.email,
            role_requested: user?.int_user_role_mapping?.int_role?.name,
            type_of_request: user.int_user_role_mapping.type_of_request || 'N/A',
            type_of_sku: user.int_user_role_mapping.type_of_sku || 'N/A',
            existing_roles: existingRoles[user.user_id] || 'N/A',
            country: user.country || 'N/A',
            requested_at: requestedAt,
            plant: user.plant || 'N/A',
            justification: user.justification,
            attachments: (user?.int_user_access_request_attachments || []).map((attachment) => {
              return { name: attachment.name, id: attachment.id };
            }),
          };
        });

      setUsers(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleAction = async (requestId, action = 'Approved') => {
    try {
      await axios.post(`user/v2/request/${requestId}`, { status: action });
      fetch();
    } catch (e) {}
  };

  const columns = useMemo(
    () => [
      {
        header: 'Actions',
        id: 'actions',
        enableColumnFilter: false,
        enableColumnSorting: false,
        enableColumnActions: false,
        size: 80,
        minSize: 80,
        maxSize: 120,
        Cell: ({ row }) => (
          <Flex gap='md'>
            <Tooltip label='Approve'>
              <ActionIcon color='green' onClick={() => handleAction(row.original.id)}>
                <IconUserCheck />
              </ActionIcon>
            </Tooltip>
            <Tooltip
              label='Reject'
              onClick={() =>
                confirm(() => handleAction(row.original.id, 'Rejected'), {
                  title: 'Confirm!',
                  description: 'Are you sure you want to reject a request?',
                  confirmationText: 'Confirm',
                })
              }
            >
              <ActionIcon color='red'>
                <IconTrash />
              </ActionIcon>
            </Tooltip>
          </Flex>
        ),
      },
      {
        header: 'Request id',
        accessorKey: 'Request_id',
        enableEditing: true,
        Cell: ({ row }) => (
          <Link onClick={() => setPreviewModal({ open: true, user: row.original })}>
            {row.original.Request_id}
          </Link>
        ),
      },
      {
        header: 'Email',
        accessorKey: 'email',
        enableEditing: false,
      },
      {
        header: 'Existing Roles',
        accessorKey: 'existing_roles',
        enableEditing: true,
      },
      {
        header: 'Role Requested',
        accessorKey: 'role_requested',
        enableEditing: true,
      },
      {
        header: 'Country',
        accessorKey: 'country',
        enableEditing: false,
      },
      {
        header: 'Requested At',
        accessorKey: 'requested_at',
        enableEditing: false,
      },
    ],
    [users],
  );

  return (
    <>
      {' '}
      <MantineTable
        columns={columns}
        initialData={users}
        unique={'id'}
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        csvFilename={`pending-users`}
        pageSize={10}
        enableExport={true}
        initialState={{
          sorting: [
            {
              id: 'requested_at',
              desc: false,
            },
          ],
        }}
      />
      {previewModal.open && <RequestPreview state={previewModal} setState={setPreviewModal} />}
    </>
  );
};

export default withConfirm(PendingUsers);
